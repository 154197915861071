// import React, { useContext, useState } from "react";
// import PatientRecord from "./PatientRecord";
// import QueueList from "./QueueList";
// import Card from "./Card";
// import config from "../../config";
// import AuthContext from "../../context/AuthContext";
// import Modal from "react-modal";
// import { FcNext, FcPrevious } from "react-icons/fc";
// import {
//   format,
//   startOfMonth,
//   endOfMonth,
//   eachDayOfInterval,
//   addMonths,
//   subMonths,
//   startOfWeek,
//   endOfWeek,
//   isSameMonth,
//   isSameDay
// } from "date-fns";
// import './style.css';


// const swal = require("sweetalert2");

// function Home({ user }) {
//   const [Id, setId] = useState(null);
//   const [Name, setName] = useState("");
//   const [Status, setStatus] = useState("");
//   const [Age, setAge] = useState("");
//   const [Gender, setGender] = useState("");
//   const [selectedPatient, setSelectedPatient] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [patientId, setPatientId] = useState();
//   const [selectedTimeSlot, setSelectedTimeSlot] = useState("morning");
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [currentMonth, setCurrentMonth] = useState(new Date()); 
//   const { authTokens } = useContext(AuthContext);
//   const cards = [
//     {
//       id: 1,
//       heading: "Send message to patient",
//     },
//     {
//       id: 2,
//       heading: "Growth",
//     },
//     {
//       id: 3,
//       heading: "Vitals",
//     },
//     {
//       id: 4,
//       heading: "",
//     },
//     {
//       id: 5,
//       heading: "Billing",
//     },
//   ];
//   const handleSelectedItem = (selectedItem) => {
//     console.log("Selected", selectedItem);
//     setId(selectedItem.id);
//     setName(selectedItem.patient_name);
//     setStatus(selectedItem.status);
//     setAge(selectedItem.patient_age);
//     setGender(selectedItem.patient_gender);
//     setPatientId(selectedItem.patient_id);
//   };
//   const handleSave = async () => {
//     const response = await fetch(
//       `${config.API_BASE_URL}/api/v1/saved-patient/add/`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${authTokens?.access}`,
//         },
//         body: JSON.stringify({
//           patient_id: patientId,
//         }),
//       }
//     );
//     if (response.status === 201) {
//       swal.fire({
//         title: "Patient Saved to Favorites",
//         icon: "success",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     } else {
//       swal.fire({
//         title: "An Error Occured " + response.status,
//         icon: "error",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };

//   const handleReschedule = () => {
//     setIsModalOpen(true);
//   };




//   const handleRescheduleSubmit = async (e) => {
//     e.preventDefault();
  
//     // Convert selectedDate to ISO string format
//     const appointmentDateTime = new Date(selectedDate).toISOString();
  
//     console.log(appointmentDateTime); // Check the output to ensure it matches the expected format
  
//     const response = await fetch(
//       `${config.API_BASE_URL}/api/v1/appointment/${Id}/reschedule/`,
//       {
//         method: "PATCH",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `JWT ${authTokens?.access}`,
//         },
//         body: JSON.stringify({
//           appointment_datetime: appointmentDateTime,
//         }),
//       }
//     );
  
//     if (response.ok) {
//       swal.fire({
//         title: "Rescheduled!",
//         text: "The appointment has been rescheduled.",
//         icon: "success",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//       setIsModalOpen(false);
//     } else {
//       const errorText = await response.text(); // Get error details from the response
//       swal.fire({
//         title: "An Error Occurred",
//         text: `Status Code: ${response.status} - ${errorText}`,
//         icon: "error",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };
  

// const handleDelete = () => {
//   swal
//     .fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     })
//     .then(async (result) => {
//       if (result.isConfirmed) {
//         const response = await fetch(
//           `${config.API_BASE_URL}/api/v1/appointment/${Id}/delete/`,
//           {
//             method: "DELETE",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `JWT ${authTokens?.access}`,
//             },
//           }
//         );
        

//         if (response.status === 200) {
//           swal.fire("Deleted!", "The patient has been deleted.", "success");
//           setSelectedPatient(null);
//         } else {
//           swal.fire({
//             title: "An Error Occurred",
//             text: `Status Code: ${response.status}`,
//             icon: "error",
//             toast: true,
//             timer: 6000,
//             position: "top-right",
//             timerProgressBar: true,
//             showConfirmButton: false,
//           });
//         }
//       }
//     });
// };



//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   // const handleRescheduleSubmit = (e) => {
//   //   e.preventDefault();
//   //   // Handle the rescheduling logic here
//   //   setIsModalOpen(false);
//   //   swal.fire("Rescheduled!", "The appointment has been rescheduled.", "success");
//   // };

//   const handleTimeSlotChange = (timeSlot) => {
//     setSelectedTimeSlot(timeSlot); 
//   };

//   // Calendar functions and rendering
//   const renderDaysOfWeek = () => {
//     const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//     return days.map((day, index) => <div key={index}>{day}</div>);
//   };

//   const renderCalendarDays = () => {
//     const startDate = startOfMonth(currentMonth);
//     const endDate = endOfMonth(currentMonth);
//     const startWeek = startOfWeek(startDate);
//     const endWeek = endOfWeek(endDate);
//     const days = eachDayOfInterval({ start: startWeek, end: endWeek });

//     return days.map((day, index) => (
//       <div
//         key={index}
//         onClick={() => setSelectedDate(day)}
//         className={`day ${
//           isSameMonth(day, currentMonth) ? "" : "other-month"
//         } ${isSameDay(day, selectedDate) ? "selected-day" : ""}`}
//       >
//         {format(day, "d")}
//       </div>
//     ));
//   };

//   return (
//     <>
//       <div className="main-container1">
//         <QueueList onItemSelected={handleSelectedItem} />
//       </div>
//       {Id === null ? (
//         <div className="noselected">No Patient Selected</div>
//       ) : (
//         <>
//           <div className="main-container2">
//             <PatientRecord
//               AppointmentId={Id}
//               PatientName={Name}
//               AppointmentStatus={Status}
//               user={user}
//               PatientAge={Age}
//               patientGender={Gender}
//             />
//           </div>
//           <div className="main-container3">

//             <div style={{display:"flex",padding:"10px 8% 10px 10px",gap:"10px"}}>
//               <button onClick={handleReschedule} className="recep-btn">
//                 Reschedule
//               </button>
//               <button onClick={handleDelete} className="recep-btn">Delete</button>
             



//             <div className="doctorstatus">
//               {user.is_doctor === false ? (
//                 <>
//                   Doctor Online
//                   <div className="online-indicator">
//                     <span className="blink"></span>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div
//                     onClick={handleSave}
//                     title="Like"
//                     className="heart-container"
//                   >
//                     <input
//                       id="Give-It-An-Id"
//                       className="checkbox"
//                       type="checkbox"
//                     />
//                     <div className="svg-container">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="svg-outline"
//                         viewBox="0 0 24 24"
//                       >
//                         <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
//                       </svg>
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="svg-filled"
//                         viewBox="0 0 24 24"
//                       >
//                         <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
//                       </svg>
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         height="100"
//                         width="100"
//                         className="svg-celebrate"
//                       >
//                         <polygon points="10,10 20,20"></polygon>
//                         <polygon points="10,50 20,50"></polygon>
//                         <polygon points="20,80 30,70"></polygon>
//                         <polygon points="90,10 80,20"></polygon>
//                         <polygon points="90,50 80,50"></polygon>
//                         <polygon points="80,80 70,70"></polygon>
//                       </svg>
//                     </div>
//                   </div>
//                   <p style={{fontSize:"1.5vw",fontWeight:"400",whiteSpace:"nowrap" ,paddingTop:"10px"}}>Save Patient</p>
//                 </>
//               )}
//             </div>
//             </div>
//             <div className="blocks">
//               {
//             cards.map((item) => {
//               return <Card key={item.id} content={item} patient={Id} />
//             })
//           }
//             </div>
//           </div>
//         </>
//       )}
//       <Modal isOpen={isModalOpen} onRequestClose={handleModalClose} className="ReactModal__Content">
//         <h2>Reschedule Appointment</h2>
//         <form onSubmit={handleRescheduleSubmit}>
//           <div>
//             <label>Select Date:</label>
//             <div className="calendar-container">
//               <div className="date-picker">
//                 <div className="month-year">
                  
                    
//                     <FcPrevious onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
//                     style={{
//                       background: "transparent",
//                       border: "none",
//                       cursor: "pointer",
//                     }}
//                  />
                
//                   <div className="current-month">
//                     {format(currentMonth, "MMMM yyyy")}
//                   </div>
        
//                     <FcNext  onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
//                     style={{
//                       background: "transparent",
//                       border: "none",
//                       cursor: "pointer",
//                     }}/>
               
//                 </div>
//                 <div className="days-of-week ">{renderDaysOfWeek()}</div>
//                 <div className="calendar-days mx-2">{renderCalendarDays()}</div>
//               </div>
//             </div>
//           </div>
//           <div>
//             <label>Select Session :</label>
//             <div className="time-slot-buttons">
//               <button
//                 type="button"
//                 className={selectedTimeSlot === "morning" ? "active" : ""}
//                 onClick={() => handleTimeSlotChange("morning")}
//               >
//                 Morning
//               </button>
//               <button
//                 type="button"
//                 className={selectedTimeSlot === "afternoon" ? "active" : ""}
//                 onClick={() => handleTimeSlotChange("afternoon")}
//               >
//                 Afternoon
//               </button>
//               <button
//                 type="button"
//                 className={selectedTimeSlot === "evening" ? "active" : ""}
//                 onClick={() => handleTimeSlotChange("evening")}
//               >
//                 Evening
//               </button>
//             </div>
//           </div>
//           <button type="submit">Submit</button>
//           <button type="button" onClick={handleModalClose}>
//             Cancel
//           </button>
//         </form>
//       </Modal>
//     </>
//   );
// }

// export default Home;




import React, { useContext, useState, useEffect } from "react";
import PatientRecord from "./PatientRecord";
import QueueList from "./QueueList";
import Card from "./Card";
import config from "../../config";
import AuthContext from "../../context/AuthContext";
import Modal from "react-modal";
import { FcNext, FcPrevious } from "react-icons/fc";
import { ArrowLeft } from "lucide-react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay
} from "date-fns";

const swal = require("sweetalert2");

function Home({ user }) {
  const [Id, setId] = useState(null);
  const [Name, setName] = useState("");
  const [Status, setStatus] = useState("");
  const [Age, setAge] = useState("");
  const [Gender, setGender] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientId, setPatientId] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("morning");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isMobile, setIsMobile] = useState(false);
  const [showQueueList, setShowQueueList] = useState(true);
  const { authTokens } = useContext(AuthContext);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600); // Changed breakpoint to 600px
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const cards = [
    { id: 1, heading: "Send message to patient" },
    { id: 2, heading: "Growth" },
    { id: 3, heading: "Vitals" },
    { id: 4, heading: "" },
    { id: 5, heading: "Billing" },
    { id: 6, heading: "" },
  ];

  const handleSelectedItem = (selectedItem) => {
    console.log("Selected", selectedItem);
    setId(selectedItem.id);
    setName(selectedItem.patient_name);
    setStatus(selectedItem.status);
    setAge(selectedItem.patient_age);
    setGender(selectedItem.patient_gender);
    setPatientId(selectedItem.patient_id);
    if (isMobile) {
      setShowQueueList(false);
    }
  };

  const handleBackToQueue = () => {
    setShowQueueList(true);
    setId(null);
  };


  const handleSave = async () => {
    const response = await fetch(
      `${config.API_BASE_URL}/api/v1/saved-patient/add/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
        body: JSON.stringify({
          patient_id: patientId,
        }),
      }
    );
    if (response.status === 201) {
      swal.fire({
        title: "Patient Saved to Favorites",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else {
      swal.fire({
        title: "An Error Occured " + response.status,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };


  const handleReschedule = () => {
    setIsModalOpen(true);
  };




  const handleRescheduleSubmit = async (e) => {
    e.preventDefault();
  
    // Convert selectedDate to ISO string format
    const appointmentDateTime = new Date(selectedDate).toISOString();
  
    console.log(appointmentDateTime); // Check the output to ensure it matches the expected format
  
    const response = await fetch(
      `${config.API_BASE_URL}/api/v1/appointment/${Id}/reschedule/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
        body: JSON.stringify({
          appointment_datetime: appointmentDateTime,
        }),
      }
    );
  
    if (response.ok) {
      swal.fire({
        title: "Rescheduled!",
        text: "The appointment has been rescheduled.",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setIsModalOpen(false);
    } else {
      const errorText = await response.text(); // Get error details from the response
      swal.fire({
        title: "An Error Occurred",
        text: `Status Code: ${response.status} - ${errorText}`,
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  

const handleDelete = () => {
  swal
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await fetch(
          `${config.API_BASE_URL}/api/v1/appointment/${Id}/delete/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${authTokens?.access}`,
            },
          }
        );
        

        if (response.status === 200) {
          swal.fire("Deleted!", "The patient has been deleted.", "success");
          setSelectedPatient(null);
        } else {
          swal.fire({
            title: "An Error Occurred",
            text: `Status Code: ${response.status}`,
            icon: "error",
            toast: true,
            timer: 6000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
};



  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot); 
  };


  const renderDaysOfWeek = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days.map((day, index) => <div key={index}>{day}</div>);
  };

  const renderCalendarDays = () => {
    const startDate = startOfMonth(currentMonth);
    const endDate = endOfMonth(currentMonth);
    const startWeek = startOfWeek(startDate);
    const endWeek = endOfWeek(endDate);
    const days = eachDayOfInterval({ start: startWeek, end: endWeek });

    return days.map((day, index) => (
      <div
        key={index}
        onClick={() => setSelectedDate(day)}
        className={`day ${
          isSameMonth(day, currentMonth) ? "" : "other-month"
        } ${isSameDay(day, selectedDate) ? "selected-day" : ""}`}
      >
        {format(day, "d")}
      </div>
    ));
  };


  const renderDesktopLayout = () => (
    <>
    <div className="main-container1">
      <QueueList onItemSelected={handleSelectedItem} />
    </div>
    {Id === null ? (
      <div className="noselected">No Patient Selected</div>
    ) : (
      <>
        <div className="main-container2">
          <PatientRecord
            AppointmentId={Id}
            PatientName={Name}
            AppointmentStatus={Status}
            user={user}
            PatientAge={Age}
            patientGender={Gender}
          />
        </div>
        <div className="main-container3">

          <div style={{display:"flex",padding:"10px 8% 10px 10px",gap:"10px"}}>
            <button onClick={handleReschedule} className="recep-btn">
              Reschedule
            </button>
            <button onClick={handleDelete} className="recep-btn">Delete</button>
           



          <div className="doctorstatus">
            {user.is_doctor === false ? (
              <>
                Doctor Online
                <div className="online-indicator">
                  <span className="blink"></span>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={handleSave}
                  title="Like"
                  className="heart-container"
                >
                  <input
                    id="Give-It-An-Id"
                    className="checkbox"
                    type="checkbox"
                  />
                  <div className="svg-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-outline"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-filled"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="100"
                      width="100"
                      className="svg-celebrate"
                    >
                      <polygon points="10,10 20,20"></polygon>
                      <polygon points="10,50 20,50"></polygon>
                      <polygon points="20,80 30,70"></polygon>
                      <polygon points="90,10 80,20"></polygon>
                      <polygon points="90,50 80,50"></polygon>
                      <polygon points="80,80 70,70"></polygon>
                    </svg>
                  </div>
                </div>
                <p style={{fontSize:"1.5vw",fontWeight:"400",whiteSpace:"nowrap" ,paddingTop:"10px"}}>Save Patient</p>
              </>
            )}
          </div>
          </div>
          <div className="blocks">
            {
          cards.map((item) => {
            return <Card key={item.id} content={item} patient={Id} />
          })
        }
          </div>
        </div>
      </>
    )}
   
  </>
  );

  const renderMobileLayout = () => (
    <div className="bg-light" >
    {showQueueList ? (
      <div className="main-container1">
        <QueueList onItemSelected={handleSelectedItem} />
      </div>
    ) : (
      <div className="d-flex flex-column " >
        <div className="bg-white p-1 shadow-sm  mb-1">
          <button
            onClick={handleBackToQueue}
            className="hoverbutton border-0 fs-4"
          >
            <ArrowLeft className="me-2" />
            Back to Queue
          </button>
        </div>
  
        <div className="flex-grow-1 overflow-auto" style={{height:"80vh"}}>
        <div className="main-container2" style={{height:"90vh"}}>
          <PatientRecord
            AppointmentId={Id}
            PatientName={Name}
            AppointmentStatus={Status}
            user={user}
            PatientAge={Age}
            patientGender={Gender}
          />
        </div>
  
        <div className="main-container3">

<div style={{display:"flex",padding:"10px 8% 10px 10px",gap:"10px"}}>
  <button onClick={handleReschedule} className="recep-btn">
    Reschedule
  </button>
  <button onClick={handleDelete} className="recep-btn">Delete</button>
 



<div className="doctorstatus">
  {user.is_doctor === false ? (
    <>
      Doctor Online
      <div className="online-indicator">
        <span className="blink"></span>
      </div>
    </>
  ) : (
    <>
      <div
        onClick={handleSave}
        title="Like"
        className="heart-container"
      >
        <input
          id="Give-It-An-Id"
          className="checkbox"
          type="checkbox"
        />
        <div className="svg-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="svg-outline"
            viewBox="0 0 24 24"
          >
            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="svg-filled"
            viewBox="0 0 24 24"
          >
            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100"
            width="100"
            className="svg-celebrate"
          >
            <polygon points="10,10 20,20"></polygon>
            <polygon points="10,50 20,50"></polygon>
            <polygon points="20,80 30,70"></polygon>
            <polygon points="90,10 80,20"></polygon>
            <polygon points="90,50 80,50"></polygon>
            <polygon points="80,80 70,70"></polygon>
          </svg>
        </div>
      </div>
      <p className="savepatient">Save Patient</p>
    </>
  )}
</div>
</div>
<div className="blocks" style={{marginBottom:"40px"}}>
  {
cards.map((item) => {
  return <Card key={item.id} content={item} patient={Id} />
})
}
</div>
</div>
        </div>
      </div>
    )}
  </div>
  
  );

  return (
    <>
      {isMobile ? renderMobileLayout() : renderDesktopLayout()}
      <Modal isOpen={isModalOpen} onRequestClose={handleModalClose} className="ReactModal__Content">
      <h2>Reschedule Appointment</h2>
      <form onSubmit={handleRescheduleSubmit}>
        <div>
          <label>Select Date:</label>
          <div className="calendar-container">
            <div className="date-picker">
              <div className="month-year">
                
                  
                  <FcPrevious onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
               />
              
                <div className="current-month">
                  {format(currentMonth, "MMMM yyyy")}
                </div>
      
                  <FcNext  onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}/>
             
              </div>
              <div className="days-of-week ">{renderDaysOfWeek()}</div>
              <div className="calendar-days mx-2">{renderCalendarDays()}</div>
            </div>
          </div>
        </div>
        <div>
          <label>Select Session :</label>
          <div className="time-slot-buttons">
            <button
              type="button"
              className={selectedTimeSlot === "morning" ? "active" : ""}
              onClick={() => handleTimeSlotChange("morning")}
            >
              Morning
            </button>
            <button
              type="button"
              className={selectedTimeSlot === "afternoon" ? "active" : ""}
              onClick={() => handleTimeSlotChange("afternoon")}
            >
              Afternoon
            </button>
            <button
              type="button"
              className={selectedTimeSlot === "evening" ? "active" : ""}
              onClick={() => handleTimeSlotChange("evening")}
            >
              Evening
            </button>
          </div>
        </div>
        <button type="submit">Submit</button>
        <button type="button" onClick={handleModalClose}>
          Cancel
        </button>
      </form>
    </Modal>
    </>
  );
}

export default Home;