

import './style.css';
import React, { useState, useRef, useEffect } from 'react';
import Account from '../components/settingpagecomponent/Account';
import Data from '../components/settingpagecomponent/Data';
import NavBar from "../components/nav_sidebar/NavBar";
import SideBar from "../components/nav_sidebar/SideBar";
import { useLocation } from "react-router-dom";
import Notification from '../components/settingpagecomponent/Notification';
import PrescriptionSetting from '../components/settingpagecomponent/PrescriptionSetting';

function Accountsettings() {
  const location = useLocation();
  const sidebarRef = useRef(null);
  const userName = location.state?.userName;
  const [selectedPage, setSelectedPage] = useState(null);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const handleClick = (page) => {
    setSelectedPage(page);
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <NavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <SideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
        userName={userName}
      />
      <div className="wrapper">
        <div className='left-side'>
          <ul className='left-list'>
            <li
              className={`leftside-list ${selectedPage === 'account' ? 'selected' : ''}`}
              onClick={() => handleClick('account')}
            >
              Account
            </li>
            <li
              className={`leftside-list ${selectedPage === 'Data' ? 'selected' : ''}`}
              onClick={() => handleClick('Data')}
            >
              Data
            </li>
            <li
              className={`leftside-list ${selectedPage === 'Notification' ? 'selected' : ''}`}
              // onClick={() => handleClick('Notification')}
            >
              Notification
            </li>
            <li
              className={`leftside-list ${selectedPage === 'Notification' ? 'selected' : ''}`}
              // onClick={() => handleClick('Notification')}
            >
              Language
            </li>
            <li
              className={`leftside-list ${selectedPage === 'PrescriptionSetting' ? 'selected' : ''}`}
              onClick={() => handleClick('PrescriptionSetting')}
            >
              Prescription Setting
            </li>
            <li
              className={`leftside-list ${selectedPage === 'Notification' ? 'selected' : ''}`}
              // onClick={() => handleClick('Notification')}
            >
              Templet
            </li>
            <li
              className={`leftside-list ${selectedPage === 'Notification' ? 'selected'  : ''}`}
              // onClick={() => handleClick('Notification')}
            >
              About
            </li>
            
          </ul>
        </div>
        <div className='right-side'>
          {selectedPage === 'account' && (
            <div className='content'>
              <Account />
            </div>
          )}
          {selectedPage === 'Data' && (
            <div className='content'>
              <Data />
            </div>
          )}
          {/* {selectedPage === 'Notification' && (
            <div className='content'>
              <Notification />
            </div>
          )} */}
          {selectedPage === 'PrescriptionSetting' && (
            <div className='content'>
              <PrescriptionSetting />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Accountsettings;
