import React, { useState, useEffect, useRef } from 'react';
import { FaClinicMedical } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import './style.css';

function Account() {
  const [isSwitchClinicPopupOpen, setIsSwitchClinicPopupOpen] = useState(false);
  const [isEditDetailsPopupOpen, setIsEditDetailsPopupOpen] = useState(false);
  const switchClinicPopupRef = useRef(null);
  const editDetailsPopupRef = useRef(null);

  // Function to toggle the Switch Clinic popup
  const toggleSwitchClinicPopup = () => {
    setIsSwitchClinicPopupOpen(!isSwitchClinicPopupOpen);
    setIsEditDetailsPopupOpen(false); // Close the other popup if open
  };

  // Function to toggle the Edit Details popup
  const toggleEditDetailsPopup = () => {
    setIsEditDetailsPopupOpen(!isEditDetailsPopupOpen);
    setIsSwitchClinicPopupOpen(false); // Close the other popup if open
  };

  // Close the popups when clicking outside of them
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSwitchClinicPopupOpen && switchClinicPopupRef.current && !switchClinicPopupRef.current.contains(event.target)) {
        setIsSwitchClinicPopupOpen(false);
      }
      if (isEditDetailsPopupOpen && editDetailsPopupRef.current && !editDetailsPopupRef.current.contains(event.target)) {
        setIsEditDetailsPopupOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSwitchClinicPopupOpen, isEditDetailsPopupOpen]);

  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [timing, setTiming] = useState("");

  useEffect(() => {
    // Fetching items from localStorage
    setClinicName(localStorage.getItem("clinicName") || "");
    setClinicAddress(localStorage.getItem("clinicAddress") || "");
    setPhone(localStorage.getItem("phone") || "");
    setTiming(localStorage.getItem("timing") || "");
  }, []);


  const handleSave = () => {
    // Saving items to localStorage
    localStorage.setItem("clinicName", clinicName);
    localStorage.setItem("clinicAddress", clinicAddress);
    localStorage.setItem("phone", phone);
    localStorage.setItem("timing", timing);
    alert("Clinic information saved successfully!");
  };


  return (
    <div className='account-container'>
      <div className='right-container'>
        <h3>Account Setup</h3>
        <p>User Information</p>
        <div className='setup-cont'>
          <img src='/user.png' alt='user' style={{ height: '140px', width: '130px', marginRight: '20px' }} />
          <div>
            <div className='d-flex'>
            <div>
          <li className='list my-3'>Email Id</li>
          <li className='list my-3'>Password</li>
          <li className='list my-3'>Phone No.</li>
          </div>
            <div className='input-fields'>
            <input type='number' placeholder='Number' className='inp' />
            
            <input type='number' placeholder='Number' className='inp' />
            
            <input type='number' placeholder='Number' className='inp' />
            

            </div>
            <div className='input-fields' style={{marginLeft:"5%"}}>
            <div className='acc-btn'>CHANGE ACCOUNT</div>
            <div  className='acc-btn'>CHANGE PASSWORD</div>
            <div  className='acc-btn'>CHANGE PHONE NO.</div>
            </div>
            </div>
          </div>
        </div>
        <p style={{ color: '#005F73', textAlign: 'center', fontWeight: '600', fontSize: '20px' }}>Logout</p>
      </div>
      <div className='right-container'>
        <div className='d-flex justify-content-between'>
          <p>Client Details</p>
          <div>
            <button className='acc-btn' style={{ marginRight: '7px' }} onClick={toggleSwitchClinicPopup}>SWITCH CLINIC</button>
            <button className='acc-btn'  onClick={handleSave} >Save</button>
          </div>
        </div>
        <div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Name :</li>
            <input type='text' className='inp' placeholder=' name '  value={clinicName}
                onChange={(e) => setClinicName(e.target.value)} />
          </div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Address :</li>
            <input type='text' className='inp'  placeholder='' value={clinicAddress}
                onChange={(e) => setClinicAddress(e.target.value)}
               />
          </div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Contact No. :</li>
            <input type='text' className='inp' placeholder='' value={phone}
                  onChange={(e) => setPhone(e.target.value)}
               />
          </div>
          <div className='setup-cont'>
            <li style={{width:"135px"}}>Clinic Timing:</li>
            <input type='text' className='inp'  placeholder='' value={timing}
                  onChange={(e) => setTiming(e.target.value)}
               />
          </div>
        </div>
      </div>

      {/* Switch Clinic Popup */}
      {isSwitchClinicPopupOpen && (
        <div className='popup-overlay'>
          <div className='popup-content' ref={switchClinicPopupRef}>
            <div className='d-flex justify-content-between'>
              <h3>Add / Switch Clinic</h3>
              <IoIosCloseCircle onClick={toggleSwitchClinicPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
            </div>
            <div className='d-flex'>
              <FaClinicMedical style={{ marginBottom: "17px", marginRight: "9px" }} />
              <p>KinderCard Padiatrics</p>
            </div>
            <button className='acc-btn'>ADD MORE</button>
            <div className='d-flex'>
              <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
              <input type='text' className='inp' style={{ maxWidth: "400px" }} />
            </div>
            <div className='d-flex'>
              <li style={{ width: '190px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
              <textarea type='text' className='textarea' />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className='acc-btn ' style={{ width: "80px" }}>Save</button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Details Popup */}
      {isEditDetailsPopupOpen && (
        <div className='popup-overlay'>
          <div className='popup-content' ref={editDetailsPopupRef}>
            <div className='d-flex justify-content-between'>
              <h3>Edit Client Details</h3>
              <IoIosCloseCircle onClick={toggleEditDetailsPopup} style={{ color: "#005F73", width: "25px", height: "30px" }} />
            </div>
            <div className='d-flex'>
              <li style={{ width: '181px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Name</li>
              <input type='text' className='inp' style={{ maxWidth: "400px" }} />
            </div>
            <div className='d-flex'>
              <li style={{ width: '186px', fontWeight: "500", whiteSpace: "nowrap" }}>Clinic Address</li>
              <textarea type='text' className='textarea' />
            </div>
            <div className='d-flex'>
            <li style={{ width: '180px', fontWeight: "500", whiteSpace: "nowrap" }}>Contact No.</li>
            <input type='number' className='inputs' placeholder='+91' />
            <input type='number' className='inp' style={{ maxWidth: '356px' }} />
          </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className='acc-btn ' style={{ width: "80px" }}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;
