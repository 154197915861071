import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ToggleSwitch = ({ isEnabled, onToggle }) => (
  <div className="d-flex align-items-center">
    <button
      onClick={() => onToggle(!isEnabled)}
      className="position-relative d-inline-flex h-100 w-100 align-items-center border rounded-pill"
    >
      <span
        className={`d-flex w-50 justify-content-center text-sm transition ${
          !isEnabled ? 'bg-primary text-white' : ''
        }`}
      >
        NO
      </span>
      <span
        className={`d-flex w-50 justify-content-center text-sm transition ${
          isEnabled ? 'bg-primary text-white' : ''
        }`}
      >
        YES
      </span>
    </button>
  </div>
);

const AlignmentButtons = ({ alignment, onAlignmentChange }) => (
  <div className="d-flex gap-2">
    <button
      type="button"
      onClick={() => onAlignmentChange('left')}
      className={`btn btn-sm ${
        alignment === 'left' ? 'btn-primary text-white' : 'btn-outline-secondary'
      }`}
    >
      Left
    </button>
    <button
      type="button"
      onClick={() => onAlignmentChange('right')}
      className={`btn btn-sm ${
        alignment === 'right' ? 'btn-primary text-white' : 'btn-outline-secondary'
      }`}
    >
      Right
    </button>
  </div>
);

const SettingsSection = ({ title, children, isEnabled, onToggle }) => (
  <div className="bg-white rounded shadow mb-3">
    <div className="px-3 py-2 border-bottom d-flex justify-content-between align-items-center">
      <h5 className="mb-0">{title}</h5>
      <ToggleSwitch isEnabled={isEnabled} onToggle={onToggle} />
    </div>
    <div className={`p-3 ${!isEnabled ? 'opacity-50 pointer-events-none' : ''}`}>
      {children}
    </div>
  </div>
);

const PrescriptionSettings = () => {
  const [settings, setSettings] = useState({
    doctor: {
      enabled: true,
      alignment: 'left',
      name: '',
      qualification: '',
      registration: '',
      mobile: ''
    },
    clinic: {
      enabled: true,
      alignment: 'right',
      name: '',
      address: '',
      mobile: '',
      timing: false,
      logo: null
    },
    signature: {
      enabled: true,
      alignment: 'left',
      line1: '',
      line2: '',
      signature: null,
      stamp: null
    }
  });

  const updateSettings = (section, field, value) => {
    setSettings(prev => {
      const newSettings = {
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      };

      // If updating alignment for doctor or clinic, sync the other section
      if (field === 'alignment' && (section === 'doctor' || section === 'clinic')) {
        const otherSection = section === 'doctor' ? 'clinic' : 'doctor';
        newSettings[otherSection] = {
          ...prev[otherSection],
          alignment: value === 'left' ? 'right' : 'left'
        };
      }

      return newSettings;
    });
  };

  const [doctorSignature, setDoctorSignature] = useState(null);
  const [doctorStamp, setDoctorStamp] = useState(null);
  const [clinicLogo, setClinicLogo] = useState(null);

  const handleDocSignatureUpload = (event) => {
    setDoctorSignature(event.target.files[0]);
  };

  const handleDocStampUpload = (event) => {
    setDoctorStamp(event.target.files[0]);
  };

  const handleClinicLogoUpload = (event) => {
    setClinicLogo(event.target.files[0]);
  };

  return (
    <div>
        <h4 className='px-4 py-2'>Prescription Setting</h4>
    <div className="container  py-2" style={{ maxHeight: '83vh', overflowY: 'auto' }}>
      <div className="space-y-4">
        {/* Doctor's Info Section */}
        <SettingsSection
          title="Doctor's Info"
          isEnabled={settings.doctor.enabled}
          onToggle={(value) => updateSettings('doctor', 'enabled', value)}
        >
          <div className="mb-3">
            <label className="form-label">Doctor's Info Alignment</label>
            <AlignmentButtons
              alignment={settings.doctor.alignment}
              onAlignmentChange={(value) => updateSettings('doctor', 'alignment', value)}
            />
          </div>
          <input
            type="text"
            placeholder="Doctor's Name"
            className="form-control mb-3"
            value={settings.doctor.name}
            onChange={(e) => updateSettings('doctor', 'name', e.target.value)}
          />
          <textarea
            placeholder="Doctor's Qualification"
            className="form-control mb-3"
            value={settings.doctor.qualification}
            onChange={(e) => updateSettings('doctor', 'qualification', e.target.value)}
          />
          <input
            type="text"
            placeholder="Registration No."
            className="form-control mb-3"
            value={settings.doctor.registration}
            onChange={(e) => updateSettings('doctor', 'registration', e.target.value)}
          />
          <input
            type="text"
            placeholder="Mobile No."
            className="form-control mb-3"
            value={settings.doctor.mobile}
            onChange={(e) => updateSettings('doctor', 'mobile', e.target.value)}
          />
        </SettingsSection>

        {/* Clinic's Info Section */}
        <SettingsSection
          title="Clinic's Info"
          isEnabled={settings.clinic.enabled}
          onToggle={(value) => updateSettings('clinic', 'enabled', value)}
        >
          <label className="form-label">Clinic's Info Alignment</label>
          <AlignmentButtons
            alignment={settings.clinic.alignment}
            onAlignmentChange={(value) => updateSettings('clinic', 'alignment', value)}
          />
          <input
            type="text"
            placeholder="Clinic's Name"
            className="form-control mb-3"
            value={settings.clinic.name}
            onChange={(e) => updateSettings('clinic', 'name', e.target.value)}
          />
          <textarea
            placeholder="Clinic Address"
            className="form-control mb-3"
            value={settings.clinic.address}
            onChange={(e) => updateSettings('clinic', 'address', e.target.value)}
          />
          <input
            type="text"
            placeholder="Mobile No."
            className="form-control mb-3"
            value={settings.clinic.mobile}
            onChange={(e) => updateSettings('clinic', 'mobile', e.target.value)}
          />
          <div className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              checked={settings.clinic.timing}
              onChange={(e) => updateSettings('clinic', 'timing', e.target.checked)}
            />
            <label className="form-check-label">Clinic's Timing</label>
          </div>
          <input
            type="file"
            className="d-none"
            id="clinic-logo"
            onChange={handleClinicLogoUpload}
          />
          <label htmlFor="clinic-logo" className="btn btn-primary mt-3">
            Upload Clinic Logo
          </label>
        </SettingsSection>

        {/* Signature Section */}
        <SettingsSection
          title="Signature"
          isEnabled={settings.signature.enabled}
          onToggle={(value) => updateSettings('signature', 'enabled', value)}
        >
          <label className="form-label">Signature Alignment</label>
          <AlignmentButtons
            alignment={settings.signature.alignment}
            onAlignmentChange={(value) => updateSettings('signature', 'alignment', value)}
          />
          <input
            type="text"
            placeholder="Line - 1"
            className="form-control mb-3"
            value={settings.signature.line1}
            onChange={(e) => updateSettings('signature', 'line1', e.target.value)}
          />
          <input
            type="text"
            placeholder="Line - 2"
            className="form-control mb-3"
            value={settings.signature.line2}
            onChange={(e) => updateSettings('signature', 'line2', e.target.value)}
          />
          <div className="gap-2">
            <div className='d-flex gap-2'>
            <input
              type="file"
              className="d-none"
              id="doc-signature"
              onChange={handleDocSignatureUpload}
            />
            <label htmlFor="doc-signature" className="btn btn-primary">
              Upload Signature
            </label>
            <label className="btn btn-primary">View</label>
            </div>
            <div  className='d-flex gap-2'>
            <input
              type="file"
              className="d-none"
              id="doc-stamp"
              onChange={handleDocStampUpload}
            />
            <label htmlFor="doc-stamp" className="btn btn-primary">
              Upload Stamp
            </label>
            <label className="btn btn-primary">View</label>
            </div>
          </div>
        </SettingsSection>
      </div>
    </div>
    </div>
  );
};

export default PrescriptionSettings;
