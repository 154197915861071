import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import config from "../../config";

const PatientDetails = () => {
  const { authTokens } = useContext(AuthContext);
  const [patients, setPatients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

  const doctorData = JSON.parse(sessionStorage.getItem("userData"));
  const doctor_id = doctorData?.id;

  // Fetch patient details
  const fetchPatientDetails = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/patient-details/doctor/${doctor_id}/appointments/`,
        {
          headers: { Authorization: `JWT ${authTokens?.access}` },
        }
      );
      console.log(response.data);
      setPatients(response.data.appointment_details || []); // Ensure an array even if undefined
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setPatients([]); // Set to empty array on error
    }
  };

  useEffect(() => {
    if (doctor_id) {
      fetchPatientDetails();
    }
  }, [doctor_id]); // Fetch when doctor_id changes

  const fetchPdf = async (appointmentId) => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/consultation/prescriptions/${appointmentId}/`,
        {
          headers: { Authorization: `JWT ${authTokens?.access}` },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const url = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        setPdfUrl(url);
      } else {
        setPdfUrl(null);
      }
    } catch (error) {
      console.error("Error fetching prescription PDF:", error);
      setPdfUrl(null);
    }
  };

  // Fetch Billing PDF
  const fetchBillingPdf = async (appointmentId) => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/billing-app/billing/${appointmentId}/pdf/`,
        {
          headers: { Authorization: `JWT ${authTokens?.access}` },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const url = URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        setPdfUrl(url);
      } else {
        setPdfUrl(null);
      }
    } catch (error) {
      console.error("Error fetching billing PDF:", error);
      setPdfUrl(null);
    }
  };

  // Handle button clicks
  const handleViewDocument = (appointmentId) => {
    setModalTitle("Prescription Document");
    setSelectedAppointmentId(appointmentId);
    fetchPdf(appointmentId);
    setShowModal(true);
  };

  const handleViewBill = (appointmentId) => {
    setModalTitle("Billing Document");
    setSelectedAppointmentId(appointmentId);
    fetchBillingPdf(appointmentId);
    setShowModal(true);
  };

  // Helper function to render vital details
  const renderVitalDetails = (vitalDetails) => {
    if (!vitalDetails || vitalDetails.length === 0) return "N/A";
    
    // Return the most recent vital details
    const latestVital = vitalDetails[vitalDetails.length - 1];
    return {
      height: latestVital.height || "N/A",
      weight: latestVital.weight || "N/A",
      bp: latestVital.bp || "N/A"
    };
  };
// console.log(patients)
  return (
    <div className="container py-3"   >
      <h4 style={{ color: "#0A9396" }}>Patient Details</h4>
      <div className="table-responsive" style={{height:"450px" , overflow:"auto"}} >
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th rowSpan="2">Sl No.</th>
              <th rowSpan="2">Patient Name</th>
              <th rowSpan="2">Appointment Booking Date</th>
              <th rowSpan="2">Session</th>
              <th rowSpan="2">Slot</th>
              <th colSpan="3">Vitals</th>
              <th rowSpan="2">Vaccine</th>
              <th rowSpan="2">Type</th>
              <th rowSpan="2">Next Follow Up Date</th>
              <th rowSpan="2">Actions</th>
            </tr>
            <tr>
              <th>Height (cm)</th>
              <th>Weight (kg)</th>
              <th>BP</th>
            </tr>
          </thead>
          <tbody>
            {patients.length > 0 ? (
              patients.map((patient, index) => {
                const vitalDetails = renderVitalDetails(patient.vital_details);
                return (
                  <tr key={patient.id}>
                    <td>{index + 1}</td>
                    <td>{`${patient.first_name || ""} ${patient.last_name || ""}`.trim() || "N/A"}</td>
                    <td>
                      {patient.appointment_datetime
                        ? new Date(patient.appointment_datetime).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td>{patient.session_details?.session_name || "N/A"}</td>
                    <td>{patient.session_details?.session_time || "N/A"}</td>
                    <td>{vitalDetails.height}</td>
                    <td>{vitalDetails.weight}</td>
                    <td>{vitalDetails.bp}</td>
                    <td>
                      {patient.vaccine_details?.length > 0
                        ? patient.vaccine_details.map((vaccine) => vaccine.vaccine_name).join(", ")
                        : "N/A"}
                    </td>
                    <td>{patient.type || "N/A"}</td>
                    <td>
                      {patient.follow_up_date
                        ? new Date(patient.follow_up_date).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td className="d-flex gap-2">
                      <button 
                        className="acc-btn" 
                        onClick={() => handleViewDocument(patient.id)}
                      >
                        View Document
                      </button>
                      <button 
                        className="acc-btn" 
                        onClick={() => handleViewBill(patient.id)}
                      >
                        View Bill
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="13" className="text-center">
                  No Patient Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="modal" style={{ display: "block" ,display:"flex",alignItems:"center",justifyContent:"center",background:"rgba(0, 0, 0, 0.5)"}} onClick={() => setShowModal(false)}>
          <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalTitle}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                  style={{fontSize:"32px",paddingBottom:"12px",background:"white"}}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                 {/* <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                  Close
                </button> */}
              </div>
              <div className="modal-body">
                {pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    title={modalTitle}
                    style={{ width: "100%", height: "500px" }}
                  ></iframe>
                ) : (
                  <p>No document available.</p>
                )}
              </div>
              <div className="modal-footer">
               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDetails;