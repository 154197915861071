import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './style.css';
import AuthContext from '../../context/AuthContext';
import config from '../../config';

function Findings({ onBack }) {
  const { authTokens } = useContext(AuthContext);
  const [isFullPageView, setIsFullPageView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [findings, setFindings] = useState([]);
  const [findingText, setFindingText] = useState('');
  const [findingName, setFindingName] = useState('');
  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctorId = doctorData?.id;

  // Fetch findings on component mount
  useEffect(() => {
    fetchFindings();
  }, []);

  const fetchFindings = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/template-creator/findings/${doctorId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
      });
      setFindings(response.data);
    } catch (error) {
      console.error('Error fetching findings:', error);
    }
  };

  const openFullPageView = () => {
    setIsFullPageView(true);
  };

  const closeFullPageView = () => {
    setIsFullPageView(false);
    onBack();
  };

  const openPopup = (index = null) => {
    setIsEditing(index !== null);
    setEditIndex(index);
    setFindingText(index !== null ? findings[index].finding_description : '');
    setFindingName(index !== null ? findings[index].findings_name : '');
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsEditing(false);
    setFindingText('');
    setFindingName('');
  };

  const saveAndClose = async () => {
    if (isEditing) {
      // Update existing finding
      try {
        const findingId = findings[editIndex].findings_id;
        const updatedFinding = {
          doctor_id: doctorId,
          findings_name: findingName,
          finding_description: findingText,
        };
        await axios.patch(`${config.API_BASE_URL}/api/v1/template-creator/findings/${doctorId}/${findingId}/`, updatedFinding, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${authTokens?.access}`,
          },
        });
        const updatedFindings = findings.map((finding, index) =>
          index === editIndex ? { ...finding, findings_name: findingName } : finding
        );
        setFindings(updatedFindings);
      } catch (error) {
        console.error('Error updating finding:', error);
      }
    } else {
      // Add new finding
      try {
        const newFinding = {
          doctor_id: doctorId,
          findings_name: findingName,
          
        findings_description: "A detailed findings description."
        };
        const response = await axios.post(`${config.API_BASE_URL}/api/v1/template-creator/findings/`, newFinding, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${authTokens?.access}`,
          },
        });
        setFindings([...findings, response.data]);
      } catch (error) {
        console.error('Error adding finding:', error);
      }
    }
    closePopup();
  };

  const deleteFinding = async (index) => {
    try {
      const findingId = findings[index].findings_id;
      await axios.delete(`${config.API_BASE_URL}/api/v1/template-creator/findings/${doctorId}/${findingId}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
      });
      const updatedFindings = findings.filter((_, i) => i !== index);
      setFindings(updatedFindings);
    } catch (error) {
      console.error('Error deleting finding:', error);
    }
  };

  return (
    <div>
      {isFullPageView ? (
        <div className="full-page-view">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Findings Notes</h4>
            <button onClick={closeFullPageView} className="acc-btn" style={{ padding: '5px 17px' }}>
              Back
            </button>
          </div>

          <label style={{ marginLeft: '5px', fontWeight: '500' }}>Search Findings</label>
          <div>
            <input
              type="text"
              placeholder="Search Findings..."
              className="search-input"
            />
            <button onClick={() => openPopup()} className="acc-btn">Add More</button>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Sl. No.</th>
                <th>Findings</th>
              </tr>
            </thead>
            <tbody>
              {findings.map((finding, index) => (
                <tr key={finding.finding_id}>
                  <td>{index + 1}</td>
                  <td>{finding.findings_name}</td>
                  <td>
                    <button onClick={() => openPopup(index)} className="edit-btn">Edit</button>
                  </td>
                  <td>
                    <button onClick={() => deleteFinding(index)} className="delete-btn">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="findings-data">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Findings Notes</h4>
            <button onClick={openFullPageView} className="acc-btn">
              View All
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Findings</th>
              </tr>
            </thead>
            <tbody>
              {findings.map((finding, index) => (
                <tr key={finding.finding_id}>
                  <td>{index + 1}</td>
                  <td>{finding.findings_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <div className="d-flex justify-content-between">
              <h4 style={{ whiteSpace: 'nowrap' }}>{isEditing ? 'Edit Finding' : 'Add New Finding'}</h4>
              <div>
                <button onClick={saveAndClose} className="acc-btn mx-1">Save and Close</button>
                <button onClick={closePopup} className="acc-btn">Back</button>
              </div>
            </div>
            <textarea
              placeholder="Enter your finding here..."
              className="textarea-input"
              value={findingName}
              onChange={(e) => setFindingName(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Findings;
