import React, { useState } from 'react';
import './style.css';
import Diagnosis from './Diagnosis';
import MedicineTemplets from './MedicineTemplates'; // Import the MedicineTemplets component
import InvestigationNotes from './InvestigationNotes';
import ReferralDoctors from './ReferralDoctors';
import PatientDetails from './Patientdetails';
import Findings from './Finding';

function Data() {
  const [activeTab, setActiveTab] = useState(null);
  const [showMedicineTemplets, setShowMedicineTemplets] = useState(false);

  const toggleTab = (tab) => {
    setActiveTab(tab === activeTab ? null : tab);
    if (tab === 'medicineTemplets') {
      setShowMedicineTemplets(true);
    } else {
      setShowMedicineTemplets(false);
    }
  };

  const handleBack = () => {
    setActiveTab(null);
    setShowMedicineTemplets(false);
  };

  return (
    <div className='data-container'>
      <h3>Data</h3>
      <div className='d-flex justify-content-between' style={{ gap: '10px' ,marginBottom:"10px" }}>
        <button
          className={`data-btn ${activeTab === 'findings' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('findings')}
        >
          Findings Notes
        </button>
        <button
          className={`data-btn ${activeTab === 'diagnosis' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('diagnosis')}
        >
          Diagnosis Notes
        </button>
        <button
          className={`data-btn ${activeTab === 'medicineTemplets' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('medicineTemplets')}
        >
          Medicine Templets
        </button>
      </div>
      <div className='d-flex justify-content-between' style={{ gap: '10px' }}>
      <button
          className={`data-btn ${activeTab === 'investigation' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('investigation')}
        >
          Investigation Notes
        </button>
        <button
          className={`data-btn ${activeTab === 'referralDoctors' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('referralDoctors')}
        >
          Referral Doctors
        </button>
        <button className={`data-btn ${activeTab === 'PatientDetails' ? 'active-tab' : ''}`}
          onClick={() => toggleTab('PatientDetails')}>Patient Details</button>
      </div>

      {/* Conditional Rendering of Findings Notes Data */}
      {activeTab === 'findings' && (
        <Findings onBack={handleBack}/>
      )}

      {/* Render Diagnosis Component when 'Diagnosis Notes' is selected */}
      {activeTab === 'diagnosis' && (
        <Diagnosis onBack={handleBack} />
      )}

      {/* Render Medicine Templets Section */}
      {showMedicineTemplets && (
        <MedicineTemplets onBack={handleBack} />
      )}
      {/* Render Medicine Templets Section */}
      {activeTab === 'investigation' && (
        <InvestigationNotes onBack={handleBack} />
      )}
      {activeTab === 'referralDoctors' && (
        <ReferralDoctors onBack={handleBack} />
      )}
      {activeTab === 'PatientDetails' && (
        <PatientDetails onBack={handleBack} />
      )}
    </div>
  );
}

export default Data;
