


import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./style.css";
import AuthContext from "../../context/AuthContext";
import config from "../../config";

function ReferralDoctors({ onBack }) {
  const { authTokens } = useContext(AuthContext);
  const [isFullPageView, setIsFullPageView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editReferId, setEditReferId] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState({
    refer_doctor_name: "",
    refer_doctor_specialization: "",
    refer_doctor_contact: ""
  });

  const API_BASE_URL = `${config.API_BASE_URL}`;

  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctor_id = doctorData?.id;

  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/template-creator/doctor-template/${doctor_id}`,
        {
          headers: { 'Authorization': `JWT ${authTokens?.access}` },
        }
      );
      setDoctors(response.data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
    }
  };

  const openFullPageView = () => {
    setIsFullPageView(true);
  };

  const closeFullPageView = () => {
    setIsFullPageView(false);
    onBack();
  };

  const openPopup = (doctor = null) => {
    if (doctor) {
      setIsEditing(true);
      setEditReferId(doctor.refer_id); // Use refer_id from the doctor object
      setDoctor({
        refer_doctor_name: doctor.refer_doctor_name,
        refer_doctor_specialization: doctor.refer_doctor_specialization,
        refer_doctor_contact: doctor.refer_doctor_contact
      });
    } else {
      setIsEditing(false);
      setEditReferId(null);
      setDoctor({
        refer_doctor_name: "",
        refer_doctor_specialization: "",
        refer_doctor_contact: ""
      });
    }
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsEditing(false);
    setDoctor({
      refer_doctor_name: "",
      refer_doctor_specialization: "",
      refer_doctor_contact: ""
    });
  };

  const saveAndClose = async () => {
    try {
      if (isEditing && editReferId) {
        // Update doctor
        await axios.patch(
          `${API_BASE_URL}/api/v1/template-creator/doctor-template/${doctor_id}/${editReferId}/`,
          doctor,
          {
            headers: { 'Authorization': `JWT ${authTokens?.access}` },
          }
        );
      } else {
        // Add new doctor
        await axios.post(
          `${API_BASE_URL}/api/v1/template-creator/doctor-template/`,
          { ...doctor, doctor_id: doctor_id },
          {
            headers: { 'Authorization': `JWT ${authTokens?.access}` },
          }
        );
      }
      fetchDoctors(); // Refresh list
      closePopup();
    } catch (error) {
      console.error("Error saving doctor:", error);
    }
  };

  const deleteDoctor = async (referId) => {
    if (!referId) {
      console.error("Invalid refer ID");
      return;
    }
    
    try {
      await axios.delete(
        `${API_BASE_URL}/api/v1/template-creator/doctor-template/${doctor_id}/${referId}/`,
        {
          headers: { 'Authorization': `JWT ${authTokens?.access}` },
        }
      );
      fetchDoctors(); // Refresh list
    } catch (error) {
      console.error("Error deleting doctor:", error);
    }
  };

  return (
    <div>
      {isFullPageView ? (
        <div className="full-page-view">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: "#0A9396" }}>Referral Doctors</h4>
            <button onClick={closeFullPageView} className="acc-btn" style={{ padding: "5px 17px" }}>
              Back
            </button>
          </div>

          <label style={{ marginLeft: "5px", fontWeight: "500" }}>Search Doctors</label>
          <div>
            <input type="text" placeholder="Search Doctors..." className="search-input" />
            <button onClick={() => openPopup()} className="acc-btn">
              Add More
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>Sl. No.</th>
                <th>Doctor Name</th>
                <th>Specialization</th>
                <th>Contact No.</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doc, index) => (
                <tr key={doc.refer_id}>
                  <td>{index + 1}</td>
                  <td>{doc.refer_doctor_name}</td>
                  <td>{doc.refer_doctor_specialization}</td>
                  <td>{doc.refer_doctor_contact}</td>
                  <td>
                    <button onClick={() => openPopup(doc)} className="edit-btn">
                      Edit
                    </button>
                    <button onClick={() => deleteDoctor(doc.refer_id)} className="delete-btn">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="referral-data">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: "#0A9396" }}>Referral Doctors</h4>
            <button onClick={openFullPageView} className="acc-btn">
              View All
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Doctor Name</th>
                <th>Specialization</th>
                <th>Contact No.</th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doc, index) => (
                <tr key={doc.refer_id}>
                  <td>{index + 1}</td>
                  <td>{doc.refer_doctor_name}</td>
                  <td>{doc.refer_doctor_specialization}</td>
                  <td>{doc.refer_doctor_contact}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <div className="d-flex justify-content-between">
              <h4>{isEditing ? "Edit Doctor" : "Add New Doctor"}</h4>
              <div>
                <button onClick={saveAndClose} className="acc-btn mx-1">
                  Save and Close
                </button>
                <button onClick={closePopup} className="acc-btn">
                  Back
                </button>
              </div>
            </div>

            <div className="d-flex">
              <div>
                <p style={{whiteSpace:"nowrap",fontWeight:"500"}}>Doctor Name:</p>
                <p style={{whiteSpace:"nowrap",fontWeight:"500"}}>Specialization:</p>
                <p style={{whiteSpace:"nowrap",fontWeight:"500"}}>Contact No.:</p>
              </div>
              <div style={{marginBottom:"5px"}}>
                <input
                  type="text"
                  placeholder="Doctor Name"
                  value={doctor.refer_doctor_name}
                  onChange={(e) => setDoctor({ ...doctor, refer_doctor_name: e.target.value })}
                  className='inp m-1'
                />
                <input
                  type="text"
                  placeholder="Specialization"
                  value={doctor.refer_doctor_specialization}
                  onChange={(e) => setDoctor({ ...doctor, refer_doctor_specialization: e.target.value })}
                  className='inp m-1'
                />
                <input
                  type="text"
                  placeholder="Contact No."
                  value={doctor.refer_doctor_contact}
                  onChange={(e) => setDoctor({ ...doctor, refer_doctor_contact: e.target.value })}
                  className='inp m-1'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReferralDoctors;