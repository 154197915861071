import React, { useContext, useEffect, useState } from "react";
import ConsultationContext from "../../context/ConsultationContext";
import AuthContext from "../../context/AuthContext";
import config from "../../config";
import Select from 'react-select/creatable';

export const DiagnosisCard = ({isSelected,setSelected,appointmentid}) => {
  const { DiagnosisAdd } = useContext(ConsultationContext);
  const [diagnosis, setDiagnosis] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const [saveddignosis, setSavedDignosis] = useState([]);
  const [previous, setprevious] = useState([]);

  useEffect(() => {
     const fetchFindings = async () => {
       try {
         const response = await fetch(`${config.API_BASE_URL}/api/v1/consultation/?appointment_id=${appointmentid}`, {
           method: "GET",
           headers: { "Authorization": `JWT ${authTokens?.access}` },
         });
         const data = await response.json();
         setDiagnosis(data[0]?.diagnosis || "");
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };

     const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctorId = doctorData?.id;

    // api to fetch saved notes
    fetch(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/${doctorId}/`, {
      method: "GET",
      headers: { "Authorization" : `JWT ${authTokens?.access}` }
    })
      .then((res) => res.json())
      .then((data) => {
        const existing = data.map((x) => ({
          value: x.diagnosis_id,
          label: x.diagnosis_name,
          note : x.diagnosis_description
        }));
        setSavedDignosis(existing)
        console.log(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    // api to fetch all the Previous Findings
    fetch(`${config.API_BASE_URL}/api/v1/consultation/`, {
      method: "GET",
      headers: { "Authorization" : `JWT ${authTokens?.access}` }
    })
      .then((res) => res.json())
      .then((data) => {
        const existing = data.map((x) => ({
          value: x.id,
          label: x.diagnosis,
        }));
        setprevious(existing)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
     fetchFindings();
  }, [authTokens, appointmentid]);

  const handleSave = async () => {
    try {
      if (diagnosis.trim() !== "") {
        await DiagnosisAdd(appointmentid,diagnosis,isChecked);
      }
      setSelected(isSelected + 1)
    } catch (error) {
      console.error("Post failed", error);
    }
  }
  const [value,setValue] = useState(null);
  const handleValuesChange = (selectedValues) => {
    setValue(selectedValues);
    const newDiagnosis = selectedValues.label ? selectedValues.label : '';
    setDiagnosis(prevDiagnosis => prevDiagnosis ? `${prevDiagnosis}, ${newDiagnosis}` : newDiagnosis);
  };
  
  const handleChange = (selectedValues) => {
    setValue(selectedValues);
    const newDiagnosis = selectedValues.label ? selectedValues.label : '';
    setDiagnosis(prevDiagnosis => prevDiagnosis ? `${prevDiagnosis}, ${newDiagnosis}` : newDiagnosis);
  };

  const handleDiagnosisChange = (e) => {
    let inputText = e.target.value;
    
    // Check if the Enter key was pressed
    if (e.nativeEvent.inputType === 'insertLineBreak') {
      const lines = inputText.split('\n');
      const lastLineIndex = lines.length - 2; // The previous line
      
      if (lastLineIndex >= 0 && !/[.,!?]$/.test(lines[lastLineIndex])) {
        lines[lastLineIndex] += ','; // Add a comma to the previous line if it doesn't end with punctuation
      }
      
      setDiagnosis(lines.join('\n'));
    } else {
      setDiagnosis(inputText);
    }
  };


  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctor_id = doctorData?.id;
  const handleSaved = async () => {
    try {
      if (diagnosis.trim() !== "") {
        const payload = {
          doctor_id: doctor_id, // Replace with dynamic doctor_id if needed
          diagnosis_name: diagnosis, // Main diagnosis
          diagnosis_description: "A detailed diagnosis description.", // Add description or make it dynamic
        };
  
        const response = await fetch(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/`, {
          method: "POST",
          headers: {
            "Authorization": `JWT ${authTokens?.access}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log("Diagnosis saved successfully:", result);
          
        } else {
          console.error("Failed to save diagnosis:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Post failed", error);
    }
  };
  

  return (
    <div className="cardContainer" style={{ flexDirection: 'column', justifyContent: 'space-evenly'}}>
      <div className="textbox-cointainer">
        <div style={{"width": '70%'}}>
        <Select
          defaultValue={value}
          options={previous}
          onChange={handleChange}
          placeholder={"Search For Diagnosis"}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              maxHeight: '20vh',
              width: '100%',
              border: '1px solid #fff',
              borderRadius: '0.625rem',
              padding: '1px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }),
            multiValue: (styles) => {
              return {
                position : 'relative',
                display: 'flex',
                margin : '5px 5px 2px 0px',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'wrap',
                backgroundColor: '#095d7e',
                borderRadius: '10px',
              };
            },
            multiValueLabel: (styles) => ({
              color: '#FFFFFF',
              fontSize: "1rem",
              padding : "0.5rem 0.4rem 0.5rem 0.4rem",
            }),
            multiValueRemove: (styles) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity : '1',
              color: 'black',
              position: 'absolute',
              right: '-5px',
              top: '-5px',
              padding: '1px',
              borderRadius: '50%',
              backgroundColor : '#EAF0FF',
              ':hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            }),
            placeholder : (baseStyles) => ({
              ...baseStyles,
              color: "#383C44",
              fontSize : "1.125rem"
            }),
            clearIndicator : (baseStyles) => ({
              ...baseStyles,
              color: 'red'
            }),
            dropdownIndicator : (baseStyles) => ({
              ...baseStyles,
              color : "#000",
              padding : "0vw 1vw 0vw 1vw"
            }),
          }}
        />
        </div>
        <div style={{"width":"30%"}}>
        <Select
          defaultValue={value}
          options={saveddignosis}
          onChange={handleValuesChange}
          placeholder={"Search For Saved Notes"}
        />
        </div>
      </div>
      <div className="consulttextbox">
          <textarea value={diagnosis} onChange={handleDiagnosisChange}  type="text" placeholder="Enter Dignosis Here"/>
          <label htmlFor="checkboxInput" className="bookmark" onClick={handleSaved}>
            <input type="checkbox" id="checkboxInput" value={isChecked} onChange={() => setIsChecked(!isChecked)}/>
            <svg
              width="40%"
              viewBox="0 0 50 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="svgIcon"
            >
              <path
                d="M46 62.0085L46 3.88139L3.99609 3.88139L3.99609 62.0085L24.5 45.5L46 62.0085Z"
                stroke="black"
                strokeWidth="7"
              ></path>
            </svg>
          </label>
        </div>
      
      <div className="buttondiv">
      <button className="hoverbutton" style={{"padding":'0px 3vw'}} onClick={()=>setSelected(isSelected + 1)}>Skip</button>
      <button className="hoverbutton" onClick={handleSave} style={{"padding":'0px 3vw','borderColor': '#74c0c3','backgroundColor': '#74c0c3','color': 'white'}}>Next</button>
      </div>
    </div>
  )
}
