// import React, { useEffect, useState ,useRef, useContext} from 'react';
// import { motion,AnimatePresence } from 'framer-motion';
// import LeaveManagement from './LeaveManagement';
// import './style.css';
// import NavBar from '../nav_sidebar/NavBar';
// import SideBar from '../nav_sidebar/SideBar';
// import { useLocation } from "react-router-dom";
// import { format, parseISO, getDay } from 'date-fns';
// import CustomCalendar from '../homepagecomponents/CustomCalendar';
// import { FaCalendarAlt } from "react-icons/fa";
// import axios from 'axios';
// import AuthContext from '../../context/AuthContext';
// import config from '../../config';


// export default function AppointmentCardContent() {

//   const { authTokens, user } = useContext(AuthContext);
//   console.log(authTokens?.access)
//   const API_BASE_URL = `${config.API_BASE_URL}/api/v1/booking-slot/slots/`;
//     const [isSelected,setSelected] = useState(() => {
//       return [];
//     });
   
//     const [isRafbaOn, setRafba] = useState(false);
//     const [isCdapvOn, setCdapv] = useState(false);
//     const [isLoading, setIsLoading] = useState(true);
   
//     const [editingSlot, setEditingSlot] = useState(null);
//     const [SlotName, setSlotName] = useState('');
//     const [slottimes, setSlottime] = useState([]);
//     const [selectedDate, setSelectedDate] = useState(new Date());
//     const [availableSlots, setAvailableSlots] = useState([]);
//     const [slotCount, setSlotCount] = useState('');
    
//     const [isSlotSetupVisible, setSlotVisible] = useState(false);
//     const [leaveSelectedSession,  setLeaveSelectedSession] = useState(null);
//     const [popupSelectedSession, setPopupSelectedSession] = useState('');
//     const [slots, setSlots] = useState([]);
    
//     // const [slotCount, setSlotCount] = useState('');
//     const [displayedSlots, setDisplayedSlots] = useState([]);

//     const togglePopup = () => {
//         setSlotVisible(!isSlotSetupVisible);

//     };

    
//     const updateAvailableSlots = (date) => {
//       if (!date) {
//         setAvailableSlots([]);
//         return;
//       }
  
//       const dayOfWeek = getDay(date);
//       const slotsForDay = addedSlots.filter((slot) => {
//         return slot.days.includes(dayOfWeek) && slot.isEnabled;
//       });
  
//       setAvailableSlots(slotsForDay);
//     };
  
   
    
//     const resetPopupState = () => {
//       setSlotName('');
//       setSelected([]);
//       setSessions(JSON.parse(JSON.stringify(DEFAULT_SESSIONS)));
//       setSlotCount('');
//     };
//       useEffect(() => {
//         // Load existing slots from localStorage when component mounts
//         const storedSlots = JSON.parse(localStorage.getItem('appointmentSlots')) || [];
//         setAddedSlots(storedSlots);

        
//          // Initialize selectedDaysBySlot from stored slots
//   const initialSelectedDays = {};
//   storedSlots.forEach(slot => {
//     initialSelectedDays[slot.id] = slot.days;
//   });
//   setSelectedDaysBySlot(initialSelectedDays);
    
//       }, []);



      
    
//       const fetchSlots = async () => {
//         try {
//           setIsLoading(true);
//           const doctorData = JSON.parse(sessionStorage.getItem('userData'));
//           const doctor_id = doctorData?.id;
//       console.log("idd",doctor_id)
//           // if (!doctor_id) {
//           //   alert('Doctor information not found. Please log in again.');
//           //   return;
//           // }
      
//           const response = await axios.get(`${config.API_BASE_URL}/api/v1/booking-slot/slots/${doctor_id}/`,
//             {
//               headers: {
//                   "Content-Type": "application/json", // Optional for GET requests
//                   Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
//               },
//           }
            
//           );
//           const slotsData = response.data;
          
//           // Update both states with the fetched data
//           setAddedSlots(slotsData);
//           setSlots(slotsData);
      
//           // Initialize selectedDaysBySlot from API data
//           const initialSelectedDays = {};
//           const dayNameToIndex = {
//             'Sunday': 0,
//             'Monday': 1,
//             'Tuesday': 2,
//             'Wednesday': 3,
//             'Thursday': 4,
//             'Friday': 5,
//             'Saturday': 6
//           };
      
//           slotsData.forEach(slot => {
//             const dayIndices = slot.days.map(dayObj => dayNameToIndex[dayObj.day]);
//             initialSelectedDays[slot.id] = dayIndices;
//           });
//           setSelectedDaysBySlot(initialSelectedDays);
          
//           console.log('Fetched slots:', slotsData); // Debug log
//         } catch (error) {
//           console.error('Error fetching slots:', error);
          
//         } finally {
//           setIsLoading(false);
//         }
//       };
    
    
//       const handleSaveAndClose = async () => {
//         const doctorData = JSON.parse(sessionStorage.getItem('userData'));
//         const doctor_id = doctorData?.id;
      
//         if (!doctor_id) {
//           alert('Doctor information not found. Please log in again.');
//           return;
//         }
      
//         if (!SlotName.trim()) {
//           alert('Please enter a slot name.');
//           return;
//         }
      
//         if (!isSelected.length) {
//           alert('Please select at least one day.');
//           return;
//         }
      
//         const usedDays = getUsedDays(editingSlot?.id);
//   const conflictingDays = isSelected.filter(day => usedDays.includes(day));
  
//   if (conflictingDays.length > 0) {
//     const conflictingDayNames = conflictingDays.map(day => buttons[day]).join(', ');
//     alert(`The following days are already used in other slots: ${conflictingDayNames}`);
//     return;
//   }
//         const hasConfiguredSession = Object.values(sessions).some(session => session.isConfigured);
//         if (!hasConfiguredSession) {
//           alert('Please configure at least one session.');
//           return;
//         }
      
//         const slotData = {
//           slotName: SlotName,
//           selectedDays: isSelected,
//           sessions: sessions,
//           doctor_id: doctor_id // Keep doctor_id in the body (it will still be used for preparing the data)
//         };
      
//         const requestData = prepareSlotDataForBackend(slotData);
      
//         // Check your data format in the console
//         console.log('Formatted Data for Backend:', requestData);
       
//         try {
//           let response;
//           // const headers = {
//           //   // 'Authorization': `Bearer ${yourAuthToken}`, 
//           //   'doctor-id': doctor_id // Sending doctor_id as a header
//           // };
          
//           if (editingSlot) {
//             response = await axios.patch(`${API_BASE_URL}gdpi/${editingSlot.id}/`,requestData,
//               {
//                 headers: {
//                     "Content-Type": "application/json", // Optional for GET requests
//                     Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
//                 },
//             }, );
//           } else {
//             console.log("hvmh",authTokens?.access)
//             response = await axios.post(API_BASE_URL, requestData,
//               {
//                 headers: {
//                     "Content-Type": "application/json", // Optional for GET requests
//                     Authorization: `JWT ${authTokens?.access}` // Replace JWT with Bearer if required
// // Attach the JWT token
//                 },
//             },);
            
//           }
      
//           if (response.status === 200 || response.status === 201) {
//             await fetchSlots();
//             setSlotVisible(false);
//             resetPopupState();
//             setEditingSlot(null);
//             console.log("fdg",response.status)
//           } else {
//             alert('Error saving slot. Please try again.');
//           }
//         } catch (error) {
//           console.error('Error saving slot:', error);
          
//           alert('Error saving slot. Please try again.');
//         }
//       };
      
      
//       const transformSessionData = (sessions, selectedDays) => {
//         const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      
//         const formattedDays = selectedDays.map(dayIndex => {
//           const configuredSessions = Object.entries(sessions)
//             .filter(([_, config]) => config.isConfigured)
//             .map(([sessionName, config]) => {
//               const formatTime = (hour, minute, ampm) => {
//                 let hours = parseInt(hour, 10);
//                 if (ampm === 'PM' && hours !== 12) hours += 12;
//                 if (ampm === 'AM' && hours === 12) hours = 0;
//                 return `${hours.toString().padStart(2, '0')}:${minute}:00`;
//               };
      
//               return {
//                 session_name: sessionName.charAt(0).toUpperCase() + sessionName.slice(1).toLowerCase(),
//                 start_time: formatTime(config.startHour, config.startMinute, config.startAmPm),
//                 end_time: formatTime(config.endHour, config.endMinute, config.endAmPm),
//                 max_patients: parseInt(config.slotCount, 10) || 0
//               };
//             });
      
//           return {
//             day: dayNames[dayIndex],
//             sessions: configuredSessions
//           };
//         });
      
//         return formattedDays;
//       };
      
//       const prepareSlotDataForBackend = (slotData) => {
//         const { slotName, selectedDays, sessions, doctor_id } = slotData;
      
//         return {
//           name: slotName,
//           doctor_idi: doctor_id || null,
//            // Ensure doctor_id is correctly passed as an integer (not string)
//           days: transformSessionData(sessions, selectedDays),
//           // Remove created_at and updated_at as they are not expected by the backend
          
//         };
//       };
      
      

      
    

    
    
//     const [selectedSessions, setSelectedSessions] = useState({});

//     const handleLeaveSessionClick = (session) => {
//       setSelectedSessions(prevState => ({
//         ...prevState,
//         [format(selectedDate, 'yyyy-MM-dd')]: session // Set session for the current date
//       }));
//     };

    
   
//     console.log(slottimes);

//     const toggleSwitch1 = () => {
//         setRafba(!isRafbaOn);
//       };
//       const toggleSwitch2 = () => {
//         setCdapv(!isCdapvOn);
//       };

//       const buttons = ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//       const Sessions = ['MORNING', 'AFTERNOON', 'EVENING'];

// // Add useEffect to load slots on component mount
// useEffect(() => {
//   const loadSlotsFromStorage = () => {
//     const storedSlots = localStorage.getItem('appointmentSlots');
//     if (storedSlots) {
//       try {
//         const parsedSlots = JSON.parse(storedSlots);
//         setAddedSlots(parsedSlots);
//       } catch (error) {
//         console.error('Error loading slots from localStorage:', error);
//         // If there's an error parsing, initialize with empty array
//         setAddedSlots([]);
//       }
//     }
//   };

//   loadSlotsFromStorage();
// }, []);
  
// const DEFAULT_SESSIONS = {
//   MORNING: {
//     startHour: '09',
//     startMinute: '00',
//     startAmPm: 'AM',
//     endHour: '12',
//     endMinute: '00',
//     endAmPm: 'PM',
//     slotCount: '',
//     isConfigured: false,
//     selected: false,
//   },
//   AFTERNOON: {
//     startHour: '01',
//     startMinute: '00',
//     startAmPm: 'PM',
//     endHour: '05',
//     endMinute: '00',
//     endAmPm: 'PM',
//     slotCount: '',
//     isConfigured: false,
//     selected: false,
//   },
//   EVENING: {
//     startHour: '06',
//     startMinute: '00',
//     startAmPm: 'PM',
//     endHour: '09',
//     endMinute: '00',
//     endAmPm: 'PM',
//     slotCount: '',
//     isConfigured: false,
//     selected: false,
//   },
// };


// const handleEditClick = async (slot) => {
//   if (!slot) return;

//   try {
//     const response = await axios.get(`${API_BASE_URL}gdpi/${slot.id}/`,
      
//         {
//           headers: {
//               "Content-Type": "application/json", // Optional for GET requests
//               Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
//           },
//       }, 
//     );
//     const slotData = response.data;

//     setEditingSlot(slotData);
//     setSlotName(slotData.name || '');

//     // Extract days from the slot data
//     const daysMap = {
//       'Sunday': 0,
//       'Monday': 1,
//       'Tuesday': 2,
//       'Wednesday': 3,
//       'Thursday': 4,
//       'Friday': 5,
//       'Saturday': 6
//     };

//     // Convert day names to day indices
//     const dayIndices = slotData.days.map(dayObj => daysMap[dayObj.day]);
//     setSelected(dayIndices);

//     // Initialize sessions with defaults
//     const initialSessions = JSON.parse(JSON.stringify(DEFAULT_SESSIONS));

//     // Process sessions from the API response
//     let selectedSession = 'Morning'; // Default to "Morning" session

//     slotData.days.forEach(dayData => {
//       dayData.sessions.forEach(session => {
//         const sessionKey = session.session_name.toUpperCase();
//         if (initialSessions[sessionKey]) {
//           // Parse times
//           const startTime = new Date(`2000-01-01T${session.start_time}`);
//           const endTime = new Date(`2000-01-01T${session.end_time}`);

//           // Convert to 12-hour format
//           let startHour = startTime.getHours();
//           const startAmPm = startHour >= 12 ? 'PM' : 'AM';
//           startHour = startHour % 12 || 12;

//           let endHour = endTime.getHours();
//           const endAmPm = endHour >= 12 ? 'PM' : 'AM';
//           endHour = endHour % 12 || 12;

//           initialSessions[sessionKey] = {
//             ...initialSessions[sessionKey],
//             startHour: String(startHour).padStart(2, '0'),
//             startMinute: String(startTime.getMinutes()).padStart(2, '0'),
//             startAmPm,
//             endHour: String(endHour).padStart(2, '0'),
//             endMinute: String(endTime.getMinutes()).padStart(2, '0'),
//             endAmPm,
//             slotCount: session.max_patients.toString(),
//             isConfigured: true,
//             selected: session.session_name === 'Morning' // Select "Morning" by default
//           };
//         }
//       });
//     });

//     // Set sessions and the active slot
//     setSessions(initialSessions);
//     setActiveSlotId(slotData.id); // Set the active slot ID
//     setActiveSession(selectedSession); // Set the default session to "Morning"
//     setSelectedDaysBySlot(prev => ({
//       ...prev,
//       [slotData.id]: dayIndices
//     }));

//     togglePopup();
//   } catch (error) {
//     console.error('Error fetching slot details:', error);
//     alert('Error loading slot details. Please try again.');
//   }
// };


// // Add this to your component's state
// const [activeSlotId, setActiveSlotId] = useState('');
// const [activeSession, setActiveSession] = useState('MORNING');

      

//     const [startHour, setStartHour] = useState('09');
//     const [startMinute, setStartMinute] = useState('00');
//     const [endHour, setEndHour] = useState('10');
//     const [endMinute, setEndMinute] = useState('00');
//     const [isStartHourDropdownOpen, setIsStartHourDropdownOpen] = useState(false);
//     const [isStartMinuteDropdownOpen, setIsStartMinuteDropdownOpen] = useState(false);
//     const [isEndHourDropdownOpen, setIsEndHourDropdownOpen] = useState(false);
//     const [isEndMinuteDropdownOpen, setIsEndMinuteDropdownOpen] = useState(false);

//     const startHourDropdownRef = useRef(null);
//     const startMinuteDropdownRef = useRef(null);
//     const endHourDropdownRef = useRef(null);
//     const endMinuteDropdownRef = useRef(null);
//     const [startAmPm, setStartAmPm] = useState('AM');
//     const [endAmPm, setEndAmPm] = useState('PM');

//     const toggleStartAmPm = () => {
//         setStartAmPm(prevAmPm => prevAmPm === 'AM' ? 'PM' : 'AM');
//     };
    
//     const toggleEndAmPm = () => {
//         setEndAmPm(prevAmPm => prevAmPm === 'AM' ? 'PM' : 'AM');
//     };
//     const formatTime = (hour, minute, amPm) => {
//         let formattedHour = parseInt(hour);
//         if (amPm === 'PM' && formattedHour !== 12) {
//             formattedHour += 12;
//         } else if (amPm === 'AM' && formattedHour === 12) {
//             formattedHour = 0;
//         }
//         return `${formattedHour.toString().padStart(2, '0')}:${minute}`;
//     };
 

//     const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
// const [slotToRemove, setSlotToRemove] = useState(null);

// const handleRemoveSlotClick = (id) => {
//   setSlotToRemove(id);
//   setShowRemoveConfirmation(true);
// };

// const handleConfirmRemove = async () => {
//   try {
//     // Attempt to delete the slot
//     await axios.delete(`${API_BASE_URL}gdpi/${slotToRemove}/`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
//       },
//     });

//     // Update state immediately to reflect removal
//     setSlots((prevSlots) => prevSlots.filter((slot) => slot.id !== slotToRemove));
//  // Update addedSlots state to reflect removal
//  setAddedSlots((prevAddedSlots) => prevAddedSlots.filter((slot) => slot.id !== slotToRemove));
//     // If slots are fetched from the backend, make sure to update them
//     await fetchSlots();

//     // Close the confirmation dialog and clear selected slot
//     setShowRemoveConfirmation(false);
//     setSlotToRemove(null);

    
//   } catch (error) {
//     console.error('Error removing slot:', error);
//     alert('Error removing slot. Please try again.');
//   }
// };




// const handleCancelRemove = () => {
//   setShowRemoveConfirmation(false);
//   setSlotToRemove(null);
// };

// useEffect(() => {
//   fetchSlots();
// }, []);
//     useEffect(() => {
//         setIsLoading(false);
//       }, []);
    
//     useEffect(() => {
//         if (isStartHourDropdownOpen && startHourDropdownRef.current) {
//             startHourDropdownRef.current.scrollTop = (parseInt(startHour, 10) - 1) * 40;
//         }
//         if (isStartMinuteDropdownOpen && startMinuteDropdownRef.current) {
//             startMinuteDropdownRef.current.scrollTop = (parseInt(startMinute, 10) - 1) * 40;
//         }
//         if (isEndHourDropdownOpen && endHourDropdownRef.current) {
//             endHourDropdownRef.current.scrollTop = (parseInt(endHour, 10) - 1) * 40;
//         }
//         if (isEndMinuteDropdownOpen && endMinuteDropdownRef.current) {
//             endMinuteDropdownRef.current.scrollTop = (parseInt(endMinute, 10) - 1) * 40;
//         }
//     }, [isStartHourDropdownOpen, isStartMinuteDropdownOpen, isEndHourDropdownOpen, isEndMinuteDropdownOpen, startHour, startMinute, endHour, endMinute]);





    

//     const location = useLocation();
//     const sidebarRef = useRef(null);
//     const userName = location.state?.userName;
  
//     const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  
    
//     const OpenSidebar = () => {
//       setOpenSidebarToggle(!openSidebarToggle);
//     };
  
//     useEffect(() => {
//       const handleClickOutside = (event) => {
//         if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//           setOpenSidebarToggle(false);
//         }
//       };
  
//       document.addEventListener("mousedown", handleClickOutside);
  
//       return () => {
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, []);

//     const handleAddMoreClick = () => {
//       setEditingSlot(null);
//       setSlotName('');
//       setSelected([]);
//       setSlotCount('');
      
//       // Reset sessions to default state
//       setSessions(JSON.parse(JSON.stringify(DEFAULT_SESSIONS)));
      
//       togglePopup();
//     };
    
//     // Optional helper function to reset a specific session
//     const resetSession = (timeOfDay) => {
//       setSessions(prev => ({
//         ...prev,
//         [timeOfDay]: {
//           ...DEFAULT_SESSIONS[timeOfDay],
//           selected: false,
//         }
//       }));
//     };
    
//     const [addedSlots, setAddedSlots] = useState(() => {
//       const storedSlots = localStorage.getItem('appointmentSlots');
//       return storedSlots ? JSON.parse(storedSlots) : [];
//     });
//     const handleToggleSlot = async (id) => {
//       try {
//         const slot = addedSlots.find(s => s.id === id);
//         if (!slot) return;
  
//         await axios.patch(`${API_BASE_URL}gdpi/${id}/`, {
//           ...slot,
//           isEnabled: !slot.isEnabled
//         });
  
//         // Refresh slots after toggle
//         await fetchSlots();
//       } catch (error) {
//         console.error('Error toggling slot:', error);
//         alert('Error toggling slot. Please try again.');
//       }
//     };

    
      

//       const TimeInput = ({ value, onClick, isOpen, options, onSelect, label }) => {
//         const dropdownRef = useRef(null);
      
//         useEffect(() => {
//           const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//               onSelect(value, false);
//             }
//           };
      
//           document.addEventListener('mousedown', handleClickOutside);
//           return () => document.removeEventListener('mousedown', handleClickOutside);
//         }, [onSelect, value]);
      
//         return (
//           <div className="relative" ref={dropdownRef}>
//             <div className="flex items-center">
//               <span className="mr-2">{label}</span>
//               <button
//                    className="custom-time-input"
//                 onClick={onClick}
//               >
//                 {value}
                
//               </button>
//             </div>
//             {isOpen && (
//               <div  style={{
//                    position: "absolute",
//                    width:"3.5vw",
//                   overflowY:"auto",
//                   height:"12vh",
//                    backgroundColor: "#fff",
//                    border: "1px solid #ccc",
//                    borderTop: "none",
//                    borderRadius: "9px",
//                    zIndex: 1000,
//                    scrollbarWidth: "none", 
//                    msOverflowStyle: "none", 
                   
//                  }}>
//                 {options.map((option) => (
//                   <div
//                     key={option}
//                     className={` cursor-pointer option-item ${option === value ? ' text-black' : ''}`}
//                     onClick={() => onSelect(option, false)}
//                     style={{
//                       // padding: "5px",
//                       textAlign: "center",
//                       cursor: "pointer",
//                       fontSize: "1vw",
//                       fontWeight: "600",
//                       borderRadius: "9px 9px 0 0",
//                       color:"grey"
                    
//                     }}
                    
//                   >
//                     {option}
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         );
//       };

//       const [selectedDaysBySlot, setSelectedDaysBySlot] = useState({});
//       const [disabledDays, setDisabledDays] = useState([]);
    
//      // Calculate disabled days whenever slots change
//   useEffect(() => {
//     const newDisabledDays = calculateDisabledDays();
//     setDisabledDays(newDisabledDays);
//   }, [selectedDaysBySlot]);

//   const calculateDisabledDays = () => {
//     const disabled = new Set();
//     // If we're editing a slot, exclude its days from the disabled list
//     const otherSlots = Object.entries(selectedDaysBySlot)
//       .filter(([id]) => id !== editingSlot?.id);
    
//     otherSlots.forEach(([_, days]) => {
//       days.forEach(day => disabled.add(day));
//     });
    
//     return Array.from(disabled);
//   };
//       // Update the handleDayClick function to check for used days
// const handleDayClick = (index) => {
//   // Get used days, excluding days from current slot if editing
//   const usedDays = getUsedDays(editingSlot?.id);
  
//   // If the day is already used by another slot and we're not editing that slot, return
//   if (usedDays.includes(index) && !editingSlot?.days?.some(d => d.day === buttons[index])) {
//     return;
//   }

//   const newSelected = [...isSelected];
//   if (newSelected.includes(index)) {
//     newSelected.splice(newSelected.indexOf(index), 1);
//   } else {
//     newSelected.push(index);
//   }
//   setSelected(newSelected);

//   // Update selectedDaysBySlot if editing
//   if (editingSlot) {
//     setSelectedDaysBySlot(prev => ({
//       ...prev,
//       [editingSlot.id]: newSelected
//     }));
//   }
// };

//       const isEditingSlotDay = (dayIndex) => {
//         if (!editingSlot) return false;
//         return selectedDaysBySlot[editingSlot.id]?.includes(dayIndex);
//       };

//       const [isLeaveToggleOn, setIsLeaveToggleOn] = useState(false);
//       const [isLeaveWholeSession,setIsLeaveWholeSession] = useState(false);

      
//       const calendarRef = useRef(null);
    
      
    
      

//       useEffect(() => {
//         if (availableSlots.length > 0) {
//           const availableSessionNames = availableSlots.map(slot => slot.session);
//           // Check if a session has already been selected for this date
//           if (!selectedSessions[format(selectedDate, 'yyyy-MM-dd')] && availableSessionNames.length > 0) {
//             // Automatically select the first available session for the new date
//             setSelectedSessions(prevState => ({
//               ...prevState,
//               [format(selectedDate, 'yyyy-MM-dd')]: availableSessionNames[0]
//             }));
//           }
//         }
//       }, [selectedDate, availableSlots]);


    
//       const [scrollY, setScrollY] = useState(0);

//       // Function to update the scroll position
//       const handleScroll = () => {
//         const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//         setScrollY(scrollTop);
//       };
    
//       useEffect(() => {
//         window.addEventListener('scroll', handleScroll);
        
//         // Clean up the event listener on component unmount
//         return () => {
//           window.removeEventListener('scroll', handleScroll);
//         };
//       }, []);





//       const [sessions, setSessions] = useState({
//         MORNING: {
//           selected: false,
//           startHour: '09',
//           startMinute: '00',
//           startAmPm: 'AM',
//           endHour: '12',
//           endMinute: '00',
//           endAmPm: 'PM',
//           slotCount: '',
//           isConfigured: false
//         },
//         AFTERNOON: {
//           selected: false,
//           startHour: '01',
//           startMinute: '00',
//           startAmPm: 'PM',
//           endHour: '05',
//           endMinute: '00',
//           endAmPm: 'PM',
//           slotCount: '',
//           isConfigured: false
//         },
//         EVENING: {
//           selected: false,
//           startHour: '06',
//           startMinute: '00',
//           startAmPm: 'PM',
//           endHour: '09',
//           endMinute: '00',
//           endAmPm: 'PM',
//           slotCount: '',
//           isConfigured: false
//         }
//       });
    
//       const [sessionData, setSessionData] = useState({
//         MORNING: null,
//         AFTERNOON: null,
//         EVENING: null
//       });
    
//       const [activeDropdown, setActiveDropdown] = useState(null);
    
//       const hours = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
//       const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
    
//       const handleSessionClick = (selectedSession) => {
//         setSessions(prevSessions => {
//           const newSessions = {};
          
//           Object.entries(prevSessions).forEach(([session, config]) => {
//             if (session === selectedSession) {
//               // Keep existing configuration if it exists
//               newSessions[session] = {
//                 ...config,
//                 selected: true
//               };
//             } else {
//               // Preserve other sessions' data but deselect them
//               newSessions[session] = {
//                 ...config,
//                 selected: false
//               };
//             }
//           });
          
//           return newSessions;
//         });
//       };
      

//       const TimeDropdown = ({ value, options, onChange, type, session, field }) => {
//         const dropdownId = `${session}-${type}-${field}`;
//         const isOpen = activeDropdown === dropdownId;
    
//         return (
//           <div className="position-relative">
//   <button
//     onClick={() => setActiveDropdown(isOpen ? null : dropdownId)}
//     className="btn btn-light px-3 py-1 rounded text-sm font-weight-medium"
//   >
//     {value}
//   </button>
//   {isOpen && (
//     <div className="position-absolute  mt-1 w-100 border border-secondary rounded shadow bg-white overflow-auto" style={{ maxHeight: '8rem', width: '4rem' ,zIndex:"1050"}}>
//       {options.map((option) => (
//         <div
//           key={option}
//           className="px-3 py-1 text-center cursor-pointer"
//           onClick={() => {
//             onChange(session, field, option);
//             setActiveDropdown(null);
//           }}
//           style={{ cursor: 'pointer' }}
//         >
//           {option}
//         </div>
//       ))}
//     </div>
//   )}
// </div>

//         );
//       };
    
//       const AmPmToggle = ({ value, onChange, session, field }) => (
//         <div className="position-relative d-inline-flex">
//       <button
//         onClick={() => onChange(session, field, value === 'AM' ? 'PM' : 'AM')}
//         className="position-relative btn btn-light w-20 h-8 rounded-pill overflow-hidden"
//         style={{
//           width: '5rem', // Approx. width for toggle
//           height: '2rem', // Approx. height for toggle
//           backgroundColor: '#e2e8f0', // Bootstrap's gray-200 equivalent
//           border: 'none',
//           padding: 0,
//         }}
//       >
//         {/* Background labels */}
//         <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-between align-items-center px-2 text-xs fw-medium">
//           <span className={`z-index-10 ${value === 'AM' ? 'text-dark' : 'text-white'}`}>AM</span>
//           <span className={`z-index-10 ${value === 'PM' ? 'text-dark' : 'text-white'}`}>PM</span>
//         </div>

//         {/* Sliding indicator with active value */}
//         <div
//           className="position-absolute bg-white shadow d-flex align-items-center justify-content-center"
//           style={{
//             width: '2rem', // Approx. width for indicator
//             height: '1.5rem', // Approx. height for indicator
//             top: '0.25rem', // Adjust to position the indicator
//             transform: value === 'AM' ? 'translateX(4px)' : 'translateX(2.75rem)',
//             transition: 'transform 0.3s ease-out', // Smooth transition effect
//             fontSize: '0.75rem', // Adjust font size as needed
//             fontWeight: 'bold', // Make the text bold
//             color: '#333', // Text color inside the indicator
//             borderRadius:"15px"
//           }}
//         >
//           {value}
//         </div>
//       </button>
//     </div>
      
//       );
    
//       const handleTimeChange = (session, field, value) => {
//         setSessions(prev => ({
//           ...prev,
//           [session]: {
//             ...prev[session],
//             [field]: value,
//             isConfigured: true,
//             selected: true // Ensure the session stays selected when updating
//           }
//         }));
//       };


//       const getUsedDays = (currentSlotId = null) => {
//   // Convert day names to indices
//   const dayNameToIndex = {
//     'Sunday': 0,
//     'Monday': 1,
//     'Tuesday': 2,
//     'Wednesday': 3,
//     'Thursday': 4,
//     'Friday': 5,
//     'Saturday': 6
//   };

//   // Get all used days except for the current slot being edited
//   const usedDays = new Set();
//   slots.forEach(slot => {
//     // Skip the current slot if we're editing
//     if (currentSlotId && slot.id === currentSlotId) {
//       return;
//     }
    
//     // Add each day from this slot to the set of used days
//     slot.days.forEach(dayObj => {
//       usedDays.add(dayNameToIndex[dayObj.day]);
//     });
//   });

//   return Array.from(usedDays);
// }

// // Update the rendering of day buttons to show blocked state
// const renderDayButtons = () => {
//   const usedDays = getUsedDays(editingSlot?.id);

//   return (
//     <div
//     style={{fontWeight: "500", marginTop: "1vh", display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"5px", marginBottom:"10px"}}
//     >
//       {buttons.map((day, index) => {
//         const isUsed = usedDays.includes(index);
//         const isSelectedDay = isSelected.includes(index);
//         const canSelect = !isUsed || (editingSlot?.days?.some(d => d.day === day));

//         return (
//           <button
//             key={index}
//             onClick={() => canSelect && handleDayClick(index)}
//             className={`day-button ${isSelectedDay ? 'selected' : ''} 
//                        ${isUsed && !canSelect ? 'blocked' : ''}`}
//             disabled={isUsed && !canSelect}
//             style={{
//               width:"auto",
//               padding:"0.5vw 1.5vw",
//               borderRadius: '12px',
//               // border: '1px solid #ddd',
//               cursor: canSelect ? 'pointer' : 'not-allowed',
//               backgroundColor: isSelectedDay 
//                 ? '#095D7E' 
//                 : isUsed && !canSelect 
//                   ? '#e9ecef' 
//                   : '#cde5e8ef',
//               color: isSelectedDay 
//                 ? '#ffffff' 
//                 : isUsed && !canSelect 
//                   ? '#6c757d' 
//                   : '#000000',
//             }}
//           >
//             {day}
//           </button>
//         );
//       })}
//     </div>
//   );
// };


//   return (
//     <>
//     <NavBar
//         OpenSidebar={OpenSidebar}
//         openSidebarToggle={openSidebarToggle}
//         userName={userName}
//       />
//       <SideBar
//         ref={sidebarRef}
//         openSidebarToggle={openSidebarToggle}
//         OpenSidebar={OpenSidebar}
//         userName={userName}
//       />
//     <motion.div style={{padding:"30px 7vw",overflowY:"auto",height:"90vh"}}>
      
    
//             <h2>Appoinment Process</h2>
//         <motion.div className='appointment'>
//             <motion.div className="first-column d-flex justify-content-between" style={{width:"100%"}}>
                          
//                         <motion.ul >
//                                 <motion.li>Required approval for Booking Appointment</motion.li>
//                                 <motion.li>Keep your contact details as Public View</motion.li>
//                         </motion.ul>
//                         <motion.ul style={{}}>
//                                 <motion.div style={{"width" : "100px","height" : "50%",marginBottom:"6px"}} className={`sliding-switch ${isRafbaOn ? 'on' : 'off'}`} onClick={toggleSwitch1}>
//                                     <motion.div className="slider">{isRafbaOn ? 'Off' : 'On'}</motion.div>
//                                     <motion.div className="label on">On</motion.div>
//                                     <motion.div className="label off">Off</motion.div>
//                                 </motion.div>
//                                 <motion.div style={{"width" : "100px","height" : "50%"}} className={`sliding-switch ${isCdapvOn ? 'on' : 'off'}`} onClick={toggleSwitch2}>
//                                     <motion.div className="slider">{isCdapvOn ? 'Off' : 'On'}</motion.div>
//                                     <motion.div className="label on">On</motion.div>
//                                     <motion.div className="label off">Off</motion.div>
        
//                                 </motion.div>
//                         </motion.ul>
//             </motion.div>

    
//             <motion.div style={{ display: 'flex', padding: '1vw', flexDirection: 'column', height: 'auto', overflowY: 'auto' }}>
//             <motion.h5>Time Slot Setup</motion.h5>

           

//             {isLoading ? (
//           <div>Loading...</div>
//         ) : addedSlots.length === 0 ? (
//           <div>No slots available</div>
//         ) : (
//           addedSlots.map((slot) => (
//             <motion.div key={slot.id} className='m-2'>
//               <motion.div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                 <motion.div className='slot-name'>{slot.name}</motion.div>
//                 <motion.div onClick={() => handleEditClick(slot)} className='setup-btn'>
//                   VIEW/EDIT
//                 </motion.div>
//                 <motion.div onClick={() => handleRemoveSlotClick(slot.id)} className='setup-btn'>
//                   REMOVE
//                 </motion.div>
//                 <motion.div 
//                   style={{ height: '30px', width: '100px' }} 
//                   className={`sliding-switch ${slot.isEnabled ? 'on' : 'off'}`} 
//                   onClick={() => handleToggleSlot(slot.id)}
//                 >
//                   <motion.div className="slider" style={{fontSize:"0.6vw"}}>
//                     {slot.isEnabled ? 'Disable ' : 'Enable'}
//                   </motion.div>
//                   <motion.div className="label on" style={{fontSize:"0.6vw"}}>Enable</motion.div>
//                   <motion.div className="label off" style={{fontSize:"0.6vw"}}>Disable</motion.div>
//                 </motion.div>
//               </motion.div>
//             </motion.div>
//           ))
//         )}


//             <motion.button className='acc-btn my-4' style={{ width: "100px" }} onClick={handleAddMoreClick}>
//                 Add More
//             </motion.button>
          
           
           
            



           
            
       


        


//         {showRemoveConfirmation && (
//   <motion.div
//     className="popup-background"
//     initial={{ opacity: 0 }}
//     animate={{ opacity: 1 }}
//     exit={{ opacity: 0 }}
//     style={{
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '100vw',
//       height: '100vh',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//     }}
//   >
//     <motion.div
//       className="popup-content"
//       initial={{ scale: 0.8, opacity: 0 }}
//       animate={{ scale: 1, opacity: 1 }}
//       exit={{ scale: 0.8, opacity: 0 }}
//       style={{
//         backgroundColor: 'white',
//         padding: '20px',
//         borderRadius: '10px',
//         width: '300px',
//       }}
//     >
//       <motion.h2>Remove Slot?</motion.h2>
//       <motion.p>Are you sure you want to remove this slot?</motion.p>
//       <motion.div style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <motion.button onClick={handleConfirmRemove} style={{ marginRight: '10px' }}>
//           Yes, Remove
//         </motion.button>
//         <motion.button onClick={handleCancelRemove} style={{ marginLeft: '10px' }}>
//           Cancel
//         </motion.button>
//       </motion.div>
//     </motion.div>
//     </motion.div>
// )}
            

//             {/* Popup Component */}
//             <AnimatePresence>
//                 {isSlotSetupVisible && (
//                     <motion.div 
//                         className="popup-background"
//                         initial={{ opacity: 0 }} 
//                         animate={{ opacity: 1 }} 
//                         exit={{ opacity: 0 }}
//                         style={{
//                             position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh',
//                             backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',
//                         }}
//                         onClick={togglePopup} // Close popup on background click
//                     >

                        
//                         <motion.div 
//                             className="popup-content" 
//                             initial={{ scale: 0.8, opacity: 0 }} 
//                             animate={{ scale: 1, opacity: 1 }} 
//                             exit={{ scale: 0.8, opacity: 0 }}
//                             style={{
//                                 backgroundColor: 'whitesmoke', padding: '3vw', borderRadius: '4vw',
//                                 width: '55vw', display: 'flex', flexDirection: 'column',
//                             }}
//                             onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
//                         >
//                             <motion.div className="slot-row" style={{display:"flex",flexDirection:"row",justifyContent:"space-between" }}>
//                                 <motion.div className='d-flex' style={{alignItems:"center"}}>
//                                 <motion.h5 style={{whiteSpace:"nowrap"}}>Slot Name</motion.h5>
//                                 <input value={SlotName} onChange={(e) => setSlotName(e.target.value)} type="text" style={{backgroundColor:"#CDE5E8", borderRadius:"10px" ,height:"30px",textAlign:"center"}}  placeholder='Slot Setup-1'/>
//                                 </motion.div>
//                                 <motion.button onClick={handleSaveAndClose} className='acc-btn' style={{height:""}}>
//                                             {editingSlot ? 'Update' : 'Save & Close'}
//                                         </motion.button>
//                             </motion.div>
//                             <motion.div style={{fontWeight:"500"}}>Select Days</motion.div>
//                             {/* <motion.div className='' style={{fontWeight: "500", marginTop: "1vh", display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"5px", marginBottom:"10px"}}>
//             {buttons.map((text, index) => (
//               <motion.div 
//                 key={index}
//                 className={`week-btn ${
//                   Array.isArray(isSelected) && isSelected.includes(index) 
//                     ? "selected" 
//                     : disabledDays.includes(index) && !isEditingSlotDay(index)
//                       ? "disabled"
//                       : ""
//                 }`}
//                 onClick={() => handleDayClick(index)}
//                 style={{
//                   opacity: disabledDays.includes(index) && !isEditingSlotDay(index) ? 0.5 : 1,
//                   cursor: disabledDays.includes(index) && !isEditingSlotDay(index) ? 'not-allowed' : 'pointer'
//                 }}
//               >
//                 {text}
//               </motion.div>
//             ))}
//           </motion.div> */}
//           {renderDayButtons()}

//                             <motion.p style={{fontWeight:"500"}}>Select Session & Timings</motion.p>
//                             <motion.div className="Session-container">
//                                 <motion.div className="row" style={{ width: "40%" ,gap:"10px"}}>
//                                 {Object.keys(sessions).map((session) => (
//       <button
//         key={session}
//         onClick={() => handleSessionClick(session)}
//         className={`btn ${sessions[session].selected ? 'popup-button Select' : 'popup-button'}`}
//       >
//         {session}
//       </button>
//     ))}
//                                 </motion.div>
                               
                               
//                                <div className="w-100 ">
 

//   <div>
//     {Object.entries(sessions).map(([sessionName, config]) => (
//       config.selected && (
//         <motion.div
//           key={sessionName}
          
//           className="container py-3  rounded shadow p-md-4 " 
//         >
//           <h3 className="fw-semibold mb-3">{sessionName}</h3>
//           <div className="">
//             <div className=" d-flex align-items-center">
//             <label className=" fw-semibold  my-2 " style={{width:"26%"}}>Start Time:</label>
//               <TimeDropdown
//                 value={config.startHour}
//                 options={hours}
//                 onChange={handleTimeChange}
//                 type="start"
//                 session={sessionName}
//                 field="startHour"
//               />
//               <span className="mx-1">:</span>
//               <TimeDropdown
//                 value={config.startMinute}
//                 options={minutes}
//                 onChange={handleTimeChange}
//                 type="start"
//                 session={sessionName}
//                 field="startMinute"
//               />
//               <AmPmToggle
//                 value={config.startAmPm}
//                 onChange={handleTimeChange}
//                 session={sessionName}
//                 field="startAmPm"
                
//               />
//             </div>

//             <div className="d-flex align-items-center">
//             <label className=" fw-semibold  my-2" style={{width:"26%"}}>End Time:</label>
            
//               <TimeDropdown
//                 value={config.endHour}
//                 options={hours}
//                 onChange={handleTimeChange}
//                 type="end"
//                 session={sessionName}
//                 field="endHour"
//               />
//               <span className="mx-1">:</span>
//               <TimeDropdown
//                 value={config.endMinute}
//                 options={minutes}
//                 onChange={handleTimeChange}
//                 type="end"
//                 session={sessionName}
//                 field="endMinute"
//               />
//               <AmPmToggle
//                 value={config.endAmPm}
//                 onChange={handleTimeChange}
//                 session={sessionName}
//                 field="endAmPm"
//               />
             
//             </div>

//             <div className=" d-flex align-items-center ">
//             <label className=" fw-semibold  my-3 " style={{width:"25%"}}>Slots:</label>
//             <div style={{maxWidth:"200px"}}>
//               <input
//                 type="number"
//                 value={config.slotCount}
//                 onChange={(e) => handleTimeChange(sessionName, 'slotCount', e.target.value)}
//                  className="form-control"
//             placeholder="Enter slots"
//               />
//               </div>
//             </div>
//           </div>
//         </motion.div>
//       )
//     ))}
//   </div>
// </div>



//                             </motion.div>


//                         </motion.div>
//                     </motion.div>
//                 )}
//             </AnimatePresence>
//         </motion.div>
        
            
//         </motion.div>
//     </motion.div>
//     </>
//   )
// }




import React, { useEffect, useState ,useRef, useContext} from 'react';
import { motion,AnimatePresence } from 'framer-motion';
import LeaveManagement from './LeaveManagement';
import './style.css';
import NavBar from '../nav_sidebar/NavBar';
import SideBar from '../nav_sidebar/SideBar';
import { useLocation } from "react-router-dom";
import { format, parseISO, getDay } from 'date-fns';
import CustomCalendar from '../homepagecomponents/CustomCalendar';
import { FaCalendarAlt } from "react-icons/fa";
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import config from '../../config';


export default function AppointmentCardContent() {

  const { authTokens, user } = useContext(AuthContext);
  console.log(authTokens?.access)
  const API_BASE_URL = `${config.API_BASE_URL}/api/v1/booking-slot/slots/`;
    const [isSelected,setSelected] = useState(() => {
      return [];
    });
   
    const [isRafbaOn, setRafba] = useState(false);
    const [isCdapvOn, setCdapv] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
   
    const [editingSlot, setEditingSlot] = useState(null);
    const [SlotName, setSlotName] = useState('');
    const [slottimes, setSlottime] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableSlots, setAvailableSlots] = useState([]);
    const [slotCount, setSlotCount] = useState('');
    
    const [isSlotSetupVisible, setSlotVisible] = useState(false);
    const [leaveSelectedSession,  setLeaveSelectedSession] = useState(null);
    const [popupSelectedSession, setPopupSelectedSession] = useState('');
    const [slots, setSlots] = useState([]);
    
    // const [slotCount, setSlotCount] = useState('');
    const [displayedSlots, setDisplayedSlots] = useState([]);

    const togglePopup = () => {
        setSlotVisible(!isSlotSetupVisible);

    };

    
    const updateAvailableSlots = (date) => {
      if (!date) {
        setAvailableSlots([]);
        return;
      }
  
      const dayOfWeek = getDay(date);
      const slotsForDay = addedSlots.filter((slot) => {
        return slot.days.includes(dayOfWeek) && slot.isEnabled;
      });
  
      setAvailableSlots(slotsForDay);
    };
  
   
    
    const resetPopupState = () => {
      setSlotName('');
      setSelected([]);
      setSessions(JSON.parse(JSON.stringify(DEFAULT_SESSIONS)));
      setSlotCount('');
    };
      useEffect(() => {
        // Load existing slots from localStorage when component mounts
        const storedSlots = JSON.parse(localStorage.getItem('appointmentSlots')) || [];
        setAddedSlots(storedSlots);

        
         // Initialize selectedDaysBySlot from stored slots
  const initialSelectedDays = {};
  storedSlots.forEach(slot => {
    initialSelectedDays[slot.id] = slot.days;
  });
  setSelectedDaysBySlot(initialSelectedDays);
    
      }, []);



      
    
      const fetchSlots = async () => {
        try {
          setIsLoading(true);
          const doctorData = JSON.parse(sessionStorage.getItem('userData'));
          const doctor_id = doctorData?.id;
      console.log("idd",doctor_id)
          // if (!doctor_id) {
          //   alert('Doctor information not found. Please log in again.');
          //   return;
          // }
      
          const response = await axios.get(`${config.API_BASE_URL}/api/v1/booking-slot/slots/${doctor_id}/`,
            {
              headers: {
                  "Content-Type": "application/json", // Optional for GET requests
                  Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
              },
          }
            
          );
          const slotsData = response.data;
          
          // Update both states with the fetched data
          setAddedSlots(slotsData);
          setSlots(slotsData);
      
          // Initialize selectedDaysBySlot from API data
          const initialSelectedDays = {};
          const dayNameToIndex = {
            'Sunday': 0,
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6
          };
      
          slotsData.forEach(slot => {
            const dayIndices = slot.days.map(dayObj => dayNameToIndex[dayObj.day]);
            initialSelectedDays[slot.id] = dayIndices;
          });
          setSelectedDaysBySlot(initialSelectedDays);
          
          console.log('Fetched slots:', slotsData); // Debug log
        } catch (error) {
          console.error('Error fetching slots:', error);
          
        } finally {
          setIsLoading(false);
        }
      };
    
    
      const handleSaveAndClose = async () => {
        const doctorData = JSON.parse(sessionStorage.getItem('userData'));
        const doctor_id = doctorData?.id;
      
        if (!doctor_id) {
          alert('Doctor information not found. Please log in again.');
          return;
        }
      
        if (!SlotName.trim()) {
          alert('Please enter a slot name.');
          return;
        }
      
        if (!isSelected.length) {
          alert('Please select at least one day.');
          return;
        }
      
        const usedDays = getUsedDays(editingSlot?.id);
  const conflictingDays = isSelected.filter(day => usedDays.includes(day));
  
  if (conflictingDays.length > 0) {
    const conflictingDayNames = conflictingDays.map(day => buttons[day]).join(', ');
    alert(`The following days are already used in other slots: ${conflictingDayNames}`);
    return;
  }
        const hasConfiguredSession = Object.values(sessions).some(session => session.isConfigured);
        if (!hasConfiguredSession) {
          alert('Please configure at least one session.');
          return;
        }
      
        const slotData = {
          slotName: SlotName,
          selectedDays: isSelected,
          sessions: sessions,
          doctor_id: doctor_id // Keep doctor_id in the body (it will still be used for preparing the data)
          
          
        };
      
        const requestData = prepareSlotDataForBackend(slotData);
      
        // Check your data format in the console
        console.log('Formatted Data for Backend:', requestData);
       
        try {
          let response;
          // const headers = {
          //   // 'Authorization': `Bearer ${yourAuthToken}`, 
          //   'doctor-id': doctor_id // Sending doctor_id as a header
          // };
          
          if (editingSlot) {
            response = await axios.patch(`${API_BASE_URL}gdpi/${editingSlot.id}/`,requestData,
              {
                headers: {
                    "Content-Type": "application/json", // Optional for GET requests
                    Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
                },
            }, );
          } else {
            console.log("hvmh",authTokens?.access)
            response = await axios.post(API_BASE_URL, requestData,
              {
                headers: {
                    "Content-Type": "application/json", // Optional for GET requests
                    Authorization: `JWT ${authTokens?.access}` // Replace JWT with Bearer if required
// Attach the JWT token
                },
            },);
            
          }
      
          if (response.status === 200 || response.status === 201) {
            await fetchSlots();
            setSlotVisible(false);
            resetPopupState();
            setEditingSlot(null);
            console.log("fdg",response.status)
          } else {
            alert('Error saving slot. Please try again.');
          }
        } catch (error) {
          console.error('Error saving slot:', error);
          
          alert('Error saving slot. Please try again.');
        }
      };
      
      
      const transformSessionData = (sessions, selectedDays) => {
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      
        const formattedDays = selectedDays.map(dayIndex => {
          const configuredSessions = Object.entries(sessions)
            .filter(([_, config]) => config.isConfigured)
            .map(([sessionName, config]) => {
              const formatTime = (hour, minute, ampm) => {
                let hours = parseInt(hour, 10);
                if (ampm === 'PM' && hours !== 12) hours += 12;
                if (ampm === 'AM' && hours === 12) hours = 0;
                return `${hours.toString().padStart(2, '0')}:${minute}:00`;
              };
      
              return {
                session_name: sessionName.charAt(0).toUpperCase() + sessionName.slice(1).toLowerCase(),
                start_time: formatTime(config.startHour, config.startMinute, config.startAmPm),
                end_time: formatTime(config.endHour, config.endMinute, config.endAmPm),
                max_patients: parseInt(config.slotCount, 10) || 0
              };
            });
      
          return {
            day: dayNames[dayIndex],
            sessions: configuredSessions
          };
        });
      
        return formattedDays;
      };
      
      const prepareSlotDataForBackend = (slotData) => {
        const { slotName, selectedDays, sessions, doctor_id } = slotData;
      
        return {
          name: slotName,
          doctor_idi: doctor_id || null,
           // Ensure doctor_id is correctly passed as an integer (not string)
          days: transformSessionData(sessions, selectedDays),
          // Remove created_at and updated_at as they are not expected by the backend
          
        };
      };
      
      

      
    

    
    
    const [selectedSessions, setSelectedSessions] = useState({});

    const handleLeaveSessionClick = (session) => {
      setSelectedSessions(prevState => ({
        ...prevState,
        [format(selectedDate, 'yyyy-MM-dd')]: session // Set session for the current date
      }));
    };

    
   
    console.log(slottimes);

    const toggleSwitch1 = () => {
        setRafba(!isRafbaOn);
      };
      const toggleSwitch2 = () => {
        setCdapv(!isCdapvOn);
      };

      const buttons = ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const Sessions = ['MORNING', 'AFTERNOON', 'EVENING'];

// Add useEffect to load slots on component mount
useEffect(() => {
  const loadSlotsFromStorage = () => {
    const storedSlots = localStorage.getItem('appointmentSlots');
    if (storedSlots) {
      try {
        const parsedSlots = JSON.parse(storedSlots);
        setAddedSlots(parsedSlots);
      } catch (error) {
        console.error('Error loading slots from localStorage:', error);
        // If there's an error parsing, initialize with empty array
        setAddedSlots([]);
      }
    }
  };

  loadSlotsFromStorage();
}, []);
  
const DEFAULT_SESSIONS = {
  MORNING: {
    startHour: '09',
    startMinute: '00',
    startAmPm: 'AM',
    endHour: '12',
    endMinute: '00',
    endAmPm: 'PM',
    slotCount: '',
    isConfigured: false,
    selected: false,
  },
  AFTERNOON: {
    startHour: '01',
    startMinute: '00',
    startAmPm: 'PM',
    endHour: '05',
    endMinute: '00',
    endAmPm: 'PM',
    slotCount: '',
    isConfigured: false,
    selected: false,
  },
  EVENING: {
    startHour: '06',
    startMinute: '00',
    startAmPm: 'PM',
    endHour: '09',
    endMinute: '00',
    endAmPm: 'PM',
    slotCount: '',
    isConfigured: false,
    selected: false,
  },
};


const handleEditClick = async (slot) => {
  if (!slot) return;

  try {
    const response = await axios.get(`${API_BASE_URL}gdpi/${slot.id}/`,
      
        {
          headers: {
              "Content-Type": "application/json", // Optional for GET requests
              Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
          },
      }, 
    );
    const slotData = response.data;

    setEditingSlot(slotData);
    setSlotName(slotData.name || '');

    // Extract days from the slot data
    const daysMap = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6
    };

    // Convert day names to day indices
    const dayIndices = slotData.days.map(dayObj => daysMap[dayObj.day]);
    setSelected(dayIndices);

    // Initialize sessions with defaults
    const initialSessions = JSON.parse(JSON.stringify(DEFAULT_SESSIONS));

    // Process sessions from the API response
    let selectedSession = 'Morning'; // Default to "Morning" session

    slotData.days.forEach(dayData => {
      dayData.sessions.forEach(session => {
        const sessionKey = session.session_name.toUpperCase();
        if (initialSessions[sessionKey]) {
          // Parse times
          const startTime = new Date(`2000-01-01T${session.start_time}`);
          const endTime = new Date(`2000-01-01T${session.end_time}`);

          // Convert to 12-hour format
          let startHour = startTime.getHours();
          const startAmPm = startHour >= 12 ? 'PM' : 'AM';
          startHour = startHour % 12 || 12;

          let endHour = endTime.getHours();
          const endAmPm = endHour >= 12 ? 'PM' : 'AM';
          endHour = endHour % 12 || 12;

          initialSessions[sessionKey] = {
            ...initialSessions[sessionKey],
            startHour: String(startHour).padStart(2, '0'),
            startMinute: String(startTime.getMinutes()).padStart(2, '0'),
            startAmPm,
            endHour: String(endHour).padStart(2, '0'),
            endMinute: String(endTime.getMinutes()).padStart(2, '0'),
            endAmPm,
            slotCount: session.max_patients.toString(),
            isConfigured: true,
            selected: session.session_name === 'Morning' // Select "Morning" by default
          };
        }
      });
    });

    // Set sessions and the active slot
    setSessions(initialSessions);
    setActiveSlotId(slotData.id); // Set the active slot ID
    setActiveSession(selectedSession); // Set the default session to "Morning"
    setSelectedDaysBySlot(prev => ({
      ...prev,
      [slotData.id]: dayIndices
    }));

    togglePopup();
  } catch (error) {
    console.error('Error fetching slot details:', error);
    alert('Error loading slot details. Please try again.');
  }
};


// Add this to your component's state
const [activeSlotId, setActiveSlotId] = useState('');
const [activeSession, setActiveSession] = useState('MORNING');

      

    const [startHour, setStartHour] = useState('09');
    const [startMinute, setStartMinute] = useState('00');
    const [endHour, setEndHour] = useState('10');
    const [endMinute, setEndMinute] = useState('00');
    const [isStartHourDropdownOpen, setIsStartHourDropdownOpen] = useState(false);
    const [isStartMinuteDropdownOpen, setIsStartMinuteDropdownOpen] = useState(false);
    const [isEndHourDropdownOpen, setIsEndHourDropdownOpen] = useState(false);
    const [isEndMinuteDropdownOpen, setIsEndMinuteDropdownOpen] = useState(false);

    const startHourDropdownRef = useRef(null);
    const startMinuteDropdownRef = useRef(null);
    const endHourDropdownRef = useRef(null);
    const endMinuteDropdownRef = useRef(null);
    const [startAmPm, setStartAmPm] = useState('AM');
    const [endAmPm, setEndAmPm] = useState('PM');

    const toggleStartAmPm = () => {
        setStartAmPm(prevAmPm => prevAmPm === 'AM' ? 'PM' : 'AM');
    };
    
    const toggleEndAmPm = () => {
        setEndAmPm(prevAmPm => prevAmPm === 'AM' ? 'PM' : 'AM');
    };
    const formatTime = (hour, minute, amPm) => {
        let formattedHour = parseInt(hour);
        if (amPm === 'PM' && formattedHour !== 12) {
            formattedHour += 12;
        } else if (amPm === 'AM' && formattedHour === 12) {
            formattedHour = 0;
        }
        return `${formattedHour.toString().padStart(2, '0')}:${minute}`;
    };
 

    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
const [slotToRemove, setSlotToRemove] = useState(null);

const handleRemoveSlotClick = (id) => {
  setSlotToRemove(id);
  setShowRemoveConfirmation(true);
};

const handleConfirmRemove = async () => {
  try {
    // Attempt to delete the slot
    await axios.delete(`${API_BASE_URL}gdpi/${slotToRemove}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
      },
    });

    // Update state immediately to reflect removal
    setSlots((prevSlots) => prevSlots.filter((slot) => slot.id !== slotToRemove));
 // Update addedSlots state to reflect removal
 setAddedSlots((prevAddedSlots) => prevAddedSlots.filter((slot) => slot.id !== slotToRemove));
    // If slots are fetched from the backend, make sure to update them
    await fetchSlots();

    // Close the confirmation dialog and clear selected slot
    setShowRemoveConfirmation(false);
    setSlotToRemove(null);

    
  } catch (error) {
    console.error('Error removing slot:', error);
    alert('Error removing slot. Please try again.');
  }
};




const handleCancelRemove = () => {
  setShowRemoveConfirmation(false);
  setSlotToRemove(null);
};

useEffect(() => {
  fetchSlots();
}, []);
    useEffect(() => {
        setIsLoading(false);
      }, []);
    
    useEffect(() => {
        if (isStartHourDropdownOpen && startHourDropdownRef.current) {
            startHourDropdownRef.current.scrollTop = (parseInt(startHour, 10) - 1) * 40;
        }
        if (isStartMinuteDropdownOpen && startMinuteDropdownRef.current) {
            startMinuteDropdownRef.current.scrollTop = (parseInt(startMinute, 10) - 1) * 40;
        }
        if (isEndHourDropdownOpen && endHourDropdownRef.current) {
            endHourDropdownRef.current.scrollTop = (parseInt(endHour, 10) - 1) * 40;
        }
        if (isEndMinuteDropdownOpen && endMinuteDropdownRef.current) {
            endMinuteDropdownRef.current.scrollTop = (parseInt(endMinute, 10) - 1) * 40;
        }
    }, [isStartHourDropdownOpen, isStartMinuteDropdownOpen, isEndHourDropdownOpen, isEndMinuteDropdownOpen, startHour, startMinute, endHour, endMinute]);





    

    const location = useLocation();
    const sidebarRef = useRef(null);
    const userName = location.state?.userName;
  
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  
    
    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setOpenSidebarToggle(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleAddMoreClick = () => {
      setEditingSlot(null);
      setSlotName('');
      setSelected([]);
      setSlotCount('');
      
      // Reset sessions to default state
      setSessions(JSON.parse(JSON.stringify(DEFAULT_SESSIONS)));
      
      togglePopup();
    };
    
    // Optional helper function to reset a specific session
    const resetSession = (timeOfDay) => {
      setSessions(prev => ({
        ...prev,
        [timeOfDay]: {
          ...DEFAULT_SESSIONS[timeOfDay],
          selected: false,
        }
      }));
    };
    
    const [addedSlots, setAddedSlots] = useState(() => {
      const storedSlots = localStorage.getItem('appointmentSlots');
      return storedSlots ? JSON.parse(storedSlots) : [];
    });
    const handleToggleSlot = async (id) => {
      try {
        const slot = addedSlots.find(s => s.id === id);
        if (!slot) return;
  
        await axios.patch(`${API_BASE_URL}gdpi/${id}/`, {
          ...slot,
          isEnabled: !slot.isEnabled
        });
  
        // Refresh slots after toggle
        await fetchSlots();
      } catch (error) {
        console.error('Error toggling slot:', error);
        alert('Error toggling slot. Please try again.');
      }
    };

    
      

      const TimeInput = ({ value, onClick, isOpen, options, onSelect, label }) => {
        const dropdownRef = useRef(null);
      
        useEffect(() => {
          const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              onSelect(value, false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
          return () => document.removeEventListener('mousedown', handleClickOutside);
        }, [onSelect, value]);
      
        return (
          <div className="relative" ref={dropdownRef}>
            <div className="flex items-center">
              <span className="mr-2">{label}</span>
              <button
                   className="custom-time-input"
                onClick={onClick}
              >
                {value}
                
              </button>
            </div>
            {isOpen && (
              <div  style={{
                   position: "absolute",
                   width:"3.5vw",
                  overflowY:"auto",
                  height:"12vh",
                   backgroundColor: "#fff",
                   border: "1px solid #ccc",
                   borderTop: "none",
                   borderRadius: "9px",
                   zIndex: 1000,
                   scrollbarWidth: "none", 
                   msOverflowStyle: "none", 
                   
                 }}>
                {options.map((option) => (
                  <div
                    key={option}
                    className={` cursor-pointer option-item ${option === value ? ' text-black' : ''}`}
                    onClick={() => onSelect(option, false)}
                    style={{
                      // padding: "5px",
                      textAlign: "center",
                      cursor: "pointer",
                      fontSize: "1vw",
                      fontWeight: "600",
                      borderRadius: "9px 9px 0 0",
                      color:"grey"
                    
                    }}
                    
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      };

      const [selectedDaysBySlot, setSelectedDaysBySlot] = useState({});
      const [disabledDays, setDisabledDays] = useState([]);
    
     // Calculate disabled days whenever slots change
  useEffect(() => {
    const newDisabledDays = calculateDisabledDays();
    setDisabledDays(newDisabledDays);
  }, [selectedDaysBySlot]);

  const calculateDisabledDays = () => {
    const disabled = new Set();
    // If we're editing a slot, exclude its days from the disabled list
    const otherSlots = Object.entries(selectedDaysBySlot)
      .filter(([id]) => id !== editingSlot?.id);
    
    otherSlots.forEach(([_, days]) => {
      days.forEach(day => disabled.add(day));
    });
    
    return Array.from(disabled);
  };
      // Update the handleDayClick function to check for used days
const handleDayClick = (index) => {
  // Get used days, excluding days from current slot if editing
  const usedDays = getUsedDays(editingSlot?.id);
  
  // If the day is already used by another slot and we're not editing that slot, return
  if (usedDays.includes(index) && !editingSlot?.days?.some(d => d.day === buttons[index])) {
    return;
  }

  const newSelected = [...isSelected];
  if (newSelected.includes(index)) {
    newSelected.splice(newSelected.indexOf(index), 1);
  } else {
    newSelected.push(index);
  }
  setSelected(newSelected);

  // Update selectedDaysBySlot if editing
  if (editingSlot) {
    setSelectedDaysBySlot(prev => ({
      ...prev,
      [editingSlot.id]: newSelected
    }));
  }
};

      const isEditingSlotDay = (dayIndex) => {
        if (!editingSlot) return false;
        return selectedDaysBySlot[editingSlot.id]?.includes(dayIndex);
      };

      const [isLeaveToggleOn, setIsLeaveToggleOn] = useState(false);
      const [isLeaveWholeSession,setIsLeaveWholeSession] = useState(false);

      
      const calendarRef = useRef(null);
    
      
    
      

      useEffect(() => {
        if (availableSlots.length > 0) {
          const availableSessionNames = availableSlots.map(slot => slot.session);
          // Check if a session has already been selected for this date
          if (!selectedSessions[format(selectedDate, 'yyyy-MM-dd')] && availableSessionNames.length > 0) {
            // Automatically select the first available session for the new date
            setSelectedSessions(prevState => ({
              ...prevState,
              [format(selectedDate, 'yyyy-MM-dd')]: availableSessionNames[0]
            }));
          }
        }
      }, [selectedDate, availableSlots]);


    
      const [scrollY, setScrollY] = useState(0);

      // Function to update the scroll position
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setScrollY(scrollTop);
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);





      const [sessions, setSessions] = useState({
        MORNING: {
          selected: false,
          startHour: '09',
          startMinute: '00',
          startAmPm: 'AM',
          endHour: '12',
          endMinute: '00',
          endAmPm: 'PM',
          slotCount: '',
          isConfigured: false
        },
        AFTERNOON: {
          selected: false,
          startHour: '01',
          startMinute: '00',
          startAmPm: 'PM',
          endHour: '05',
          endMinute: '00',
          endAmPm: 'PM',
          slotCount: '',
          isConfigured: false
        },
        EVENING: {
          selected: false,
          startHour: '06',
          startMinute: '00',
          startAmPm: 'PM',
          endHour: '09',
          endMinute: '00',
          endAmPm: 'PM',
          slotCount: '',
          isConfigured: false
        }
      });
    
      const [sessionData, setSessionData] = useState({
        MORNING: null,
        AFTERNOON: null,
        EVENING: null
      });
    
      const [activeDropdown, setActiveDropdown] = useState(null);
    
      const hours = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
      const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));
    
      const handleSessionClick = (selectedSession) => {
        setSessions(prevSessions => {
          const newSessions = {};
          
          Object.entries(prevSessions).forEach(([session, config]) => {
            if (session === selectedSession) {
              // Keep existing configuration if it exists
              newSessions[session] = {
                ...config,
                selected: true
              };
            } else {
              // Preserve other sessions' data but deselect them
              newSessions[session] = {
                ...config,
                selected: false
              };
            }
          });
          
          return newSessions;
        });
      };
      

      const TimeDropdown = ({ value, options, onChange, type, session, field }) => {
        const dropdownId = `${session}-${type}-${field}`;
        const isOpen = activeDropdown === dropdownId;
    
        return (
          <div className="position-relative">
  <button
    onClick={() => setActiveDropdown(isOpen ? null : dropdownId)}
    className="btn btn-light px-3 py-1 rounded text-sm font-weight-medium"
  >
    {value}
  </button>
  {isOpen && (
    <div className="position-absolute  mt-1 w-100 border border-secondary rounded shadow bg-white overflow-auto" style={{ maxHeight: '8rem', width: '4rem' ,zIndex:"1050"}}>
      {options.map((option) => (
        <div
          key={option}
          className="px-3 py-1 text-center cursor-pointer"
          onClick={() => {
            onChange(session, field, option);
            setActiveDropdown(null);
          }}
          style={{ cursor: 'pointer' }}
        >
          {option}
        </div>
      ))}
    </div>
  )}
</div>

        );
      };
    
      const AmPmToggle = ({ value, onChange, session, field }) => (
        <div className="position-relative d-inline-flex">
      <button
        onClick={() => onChange(session, field, value === 'AM' ? 'PM' : 'AM')}
        className="position-relative btn btn-light w-20 h-8 rounded-pill overflow-hidden"
        style={{
          width: '5rem', // Approx. width for toggle
          height: '2rem', // Approx. height for toggle
          backgroundColor: '#e2e8f0', // Bootstrap's gray-200 equivalent
          border: 'none',
          padding: 0,
        }}
      >
        {/* Background labels */}
        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-between align-items-center px-2 text-xs fw-medium">
          <span className={`z-index-10 ${value === 'AM' ? 'text-dark' : 'text-white'}`}>AM</span>
          <span className={`z-index-10 ${value === 'PM' ? 'text-dark' : 'text-white'}`}>PM</span>
        </div>

        {/* Sliding indicator with active value */}
        <div
          className="position-absolute bg-white shadow d-flex align-items-center justify-content-center"
          style={{
            width: '2rem', // Approx. width for indicator
            height: '1.5rem', // Approx. height for indicator
            top: '0.25rem', // Adjust to position the indicator
            transform: value === 'AM' ? 'translateX(4px)' : 'translateX(2.75rem)',
            transition: 'transform 0.3s ease-out', // Smooth transition effect
            fontSize: '0.75rem', // Adjust font size as needed
            fontWeight: 'bold', // Make the text bold
            color: '#333', // Text color inside the indicator
            borderRadius:"15px"
          }}
        >
          {value}
        </div>
      </button>
    </div>
      
      );
    
      const handleTimeChange = (session, field, value) => {
        setSessions(prev => ({
          ...prev,
          [session]: {
            ...prev[session],
            [field]: value,
            isConfigured: true,
            selected: true // Ensure the session stays selected when updating
          }
        }));
      };


      const getUsedDays = (currentSlotId = null) => {
  // Convert day names to indices
  const dayNameToIndex = {
    'Sunday': 0,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6
  };

  // Get all used days except for the current slot being edited
  const usedDays = new Set();
  slots.forEach(slot => {
    // Skip the current slot if we're editing
    if (currentSlotId && slot.id === currentSlotId) {
      return;
    }
    
    // Add each day from this slot to the set of used days
    slot.days.forEach(dayObj => {
      usedDays.add(dayNameToIndex[dayObj.day]);
    });
  });

  return Array.from(usedDays);
}

// Update the rendering of day buttons to show blocked state
const renderDayButtons = () => {
  const usedDays = getUsedDays(editingSlot?.id);

  return (
    <div
    style={{fontWeight: "500", marginTop: "1vh", display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"5px", marginBottom:"10px"}}
    >
      {buttons.map((day, index) => {
        const isUsed = usedDays.includes(index);
        const isSelectedDay = isSelected.includes(index);
        const canSelect = !isUsed || (editingSlot?.days?.some(d => d.day === day));

        return (
          <button
            key={index}
            onClick={() => canSelect && handleDayClick(index)}
            className={`day-button ${isSelectedDay ? 'selected' : ''} 
                       ${isUsed && !canSelect ? 'blocked' : ''}`}
            disabled={isUsed && !canSelect}
            style={{
              width:"auto",
              padding:"0.5vw 1.5vw",
              borderRadius: '12px',
              // border: '1px solid #ddd',
              cursor: canSelect ? 'pointer' : 'not-allowed',
              backgroundColor: isSelectedDay 
                ? '#095D7E' 
                : isUsed && !canSelect 
                  ? '#e9ecef' 
                  : '#cde5e8ef',
              color: isSelectedDay 
                ? '#ffffff' 
                : isUsed && !canSelect 
                  ? '#6c757d' 
                  : '#000000',
            }}
          >
            {day}
          </button>
        );
      })}
    </div>
  );
};


  return (
    <>
    <NavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <SideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
        userName={userName}
      />
    <motion.div style={{padding:"30px 7vw",overflowY:"auto",height:"90vh"}}>
      
    
            <h2>Appoinment Process</h2>
        <motion.div className='appointment'>
            <motion.div className="first-column d-flex justify-content-between" style={{width:"100%"}}>
                          
                        <motion.ul >
                                <motion.li>Required approval for Booking Appointment</motion.li>
                                <motion.li>Keep your contact details as Public View</motion.li>
                        </motion.ul>
                        <motion.ul style={{}}>
                                <motion.div style={{"width" : "100px","height" : "50%",marginBottom:"6px"}} className={`sliding-switch ${isRafbaOn ? 'on' : 'off'}`} onClick={toggleSwitch1}>
                                    <motion.div className="slider">{isRafbaOn ? 'Off' : 'On'}</motion.div>
                                    <motion.div className="label on">On</motion.div>
                                    <motion.div className="label off">Off</motion.div>
                                </motion.div>
                                <motion.div style={{"width" : "100px","height" : "50%"}} className={`sliding-switch ${isCdapvOn ? 'on' : 'off'}`} onClick={toggleSwitch2}>
                                    <motion.div className="slider">{isCdapvOn ? 'Off' : 'On'}</motion.div>
                                    <motion.div className="label on">On</motion.div>
                                    <motion.div className="label off">Off</motion.div>
        
                                </motion.div>
                        </motion.ul>
            </motion.div>

    
            <motion.div style={{ display: 'flex', padding: '1vw', flexDirection: 'column', height: 'auto', overflowY: 'auto' }}>
            <motion.h5>Time Slot Setup</motion.h5>

           

            {isLoading ? (
          <div>Loading...</div>
        ) : addedSlots.length === 0 ? (
          <div>No slots available</div>
        ) : (
          addedSlots.map((slot) => (
            <motion.div key={slot.id} className='m-2'>
              <motion.div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <motion.div className='slot-name'>{slot.name}</motion.div>
                <motion.div onClick={() => handleEditClick(slot)} className='setup-btn'>
                  VIEW/EDIT
                </motion.div>
                <motion.div onClick={() => handleRemoveSlotClick(slot.id)} className='setup-btn'>
                  REMOVE
                </motion.div>
                <motion.div 
                  style={{ height: '30px', width: '100px' }} 
                  className={`sliding-switch ${slot.isEnabled ? 'on' : 'off'}`} 
                  onClick={() => handleToggleSlot(slot.id)}
                >
                  <motion.div className="slider" style={{fontSize:"0.6vw"}}>
                    {slot.isEnabled ? 'Disable ' : 'Enable'}
                  </motion.div>
                  <motion.div className="label on" style={{fontSize:"0.6vw"}}>Enable</motion.div>
                  <motion.div className="label off" style={{fontSize:"0.6vw"}}>Disable</motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          ))
        )}


            <motion.button className='acc-btn my-4' style={{ width: "100px" }} onClick={handleAddMoreClick}>
                Add More
            </motion.button>
          
           
           
            



           
            
       


        


        {showRemoveConfirmation && (
  <motion.div
    className="popup-background"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <motion.div
      className="popup-content"
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        width: '300px',
      }}
    >
      <motion.h2>Remove Slot?</motion.h2>
      <motion.p>Are you sure you want to remove this slot?</motion.p>
      <motion.div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <motion.button onClick={handleConfirmRemove} style={{ marginRight: '10px' }}>
          Yes, Remove
        </motion.button>
        <motion.button onClick={handleCancelRemove} style={{ marginLeft: '10px' }}>
          Cancel
        </motion.button>
      </motion.div>
    </motion.div>
    </motion.div>
)}
            

            {/* Popup Component */}
            <AnimatePresence>
                {isSlotSetupVisible && (
                    <motion.div 
                        className="popup-background"
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }} 
                        exit={{ opacity: 0 }}
                        style={{
                            position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        }}
                        onClick={togglePopup} // Close popup on background click
                    >

                        
                        <motion.div 
                            className="popup-content" 
                            initial={{ scale: 0.8, opacity: 0 }} 
                            animate={{ scale: 1, opacity: 1 }} 
                            exit={{ scale: 0.8, opacity: 0 }}
                            style={{
                                backgroundColor: 'whitesmoke', padding: '3vw', borderRadius: '4vw',
                                width: '55vw', display: 'flex', flexDirection: 'column',
                            }}
                            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                        >
                            <motion.div className="slot-row" style={{display:"flex",flexDirection:"row",justifyContent:"space-between" }}>
                                <motion.div className='d-flex' style={{alignItems:"center"}}>
                                <motion.h5 style={{whiteSpace:"nowrap"}}>Slot Name</motion.h5>
                                <input value={SlotName} onChange={(e) => setSlotName(e.target.value)} type="text" style={{backgroundColor:"#CDE5E8", borderRadius:"10px" ,height:"30px",textAlign:"center"}}  placeholder='Slot Setup-1'/>
                                </motion.div>
                                <motion.button onClick={handleSaveAndClose} className='acc-btn' style={{height:""}}>
                                            {editingSlot ? 'Update' : 'Save & Close'}
                                        </motion.button>
                            </motion.div>
                            <motion.div style={{fontWeight:"500"}}>Select Days</motion.div>
                            {/* <motion.div className='' style={{fontWeight: "500", marginTop: "1vh", display:"flex", flexDirection:"row", justifyContent:"space-between", gap:"5px", marginBottom:"10px"}}>
            {buttons.map((text, index) => (
              <motion.div 
                key={index}
                className={`week-btn ${
                  Array.isArray(isSelected) && isSelected.includes(index) 
                    ? "selected" 
                    : disabledDays.includes(index) && !isEditingSlotDay(index)
                      ? "disabled"
                      : ""
                }`}
                onClick={() => handleDayClick(index)}
                style={{
                  opacity: disabledDays.includes(index) && !isEditingSlotDay(index) ? 0.5 : 1,
                  cursor: disabledDays.includes(index) && !isEditingSlotDay(index) ? 'not-allowed' : 'pointer'
                }}
              >
                {text}
              </motion.div>
            ))}
          </motion.div> */}
          {renderDayButtons()}

                            <motion.p style={{fontWeight:"500"}}>Select Session & Timings</motion.p>
                            <motion.div className="Session-container">
                                <motion.div className="row" style={{ width: "40%" ,gap:"10px"}}>
                                {Object.keys(sessions).map((session) => (
      <button
        key={session}
        onClick={() => handleSessionClick(session)}
        className={`btn ${sessions[session].selected ? 'popup-button Select' : 'popup-button'}`}
      >
        {session}
      </button>
    ))}
                                </motion.div>
                               
                               
                               <div className="w-100 ">
 

  <div>
    {Object.entries(sessions).map(([sessionName, config]) => (
      config.selected && (
        <motion.div
          key={sessionName}
          
          className="container py-3  rounded shadow p-md-4 " 
        >
          <h3 className="fw-semibold mb-3">{sessionName}</h3>
          <div className="">
            <div className=" d-flex align-items-center">
            <label className=" fw-semibold  my-2 " style={{width:"26%"}}>Start Time:</label>
              <TimeDropdown
                value={config.startHour}
                options={hours}
                onChange={handleTimeChange}
                type="start"
                session={sessionName}
                field="startHour"
              />
              <span className="mx-1">:</span>
              <TimeDropdown
                value={config.startMinute}
                options={minutes}
                onChange={handleTimeChange}
                type="start"
                session={sessionName}
                field="startMinute"
              />
              <AmPmToggle
                value={config.startAmPm}
                onChange={handleTimeChange}
                session={sessionName}
                field="startAmPm"
                
              />
            </div>

            <div className="d-flex align-items-center">
            <label className=" fw-semibold  my-2" style={{width:"26%"}}>End Time:</label>
            
              <TimeDropdown
                value={config.endHour}
                options={hours}
                onChange={handleTimeChange}
                type="end"
                session={sessionName}
                field="endHour"
              />
              <span className="mx-1">:</span>
              <TimeDropdown
                value={config.endMinute}
                options={minutes}
                onChange={handleTimeChange}
                type="end"
                session={sessionName}
                field="endMinute"
              />
              <AmPmToggle
                value={config.endAmPm}
                onChange={handleTimeChange}
                session={sessionName}
                field="endAmPm"
              />
             
            </div>

            <div className=" d-flex align-items-center ">
            <label className=" fw-semibold  my-3 " style={{width:"25%"}}>Slots:</label>
            <div style={{maxWidth:"200px"}}>
              <input
                type="number"
                value={config.slotCount}
                onChange={(e) => handleTimeChange(sessionName, 'slotCount', e.target.value)}
                 className="form-control"
            placeholder="Enter slots"
              />
              </div>
            </div>
          </div>
        </motion.div>
      )
    ))}
  </div>
</div>



                            </motion.div>


                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
        
            
        </motion.div>
    </motion.div>
    </>
  )
}
