import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './style.css';
import AuthContext from '../../context/AuthContext';
import config from '../../config';

function InvestigationNotes({ onBack }) {
  const [isFullPageView, setIsFullPageView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [notes, setNotes] = useState([]);  // Initialize as an empty array
  const [noteText, setNoteText] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState('');

  const { authTokens } = useContext(AuthContext);

  const API_BASE_URL = `${config.API_BASE_URL}`; // Replace with your API base URL

  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctor_id = doctorData?.id;

  // Fetch notes from the backend
  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/template-creator/investigations/${doctor_id}`, {
        headers: { 'Authorization': `JWT ${authTokens?.access}` },
      });
      setNotes(response.data.investigations); // Ensure that notes is always an array
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setLoading(false);
    }
  };
console.log(notes)
  const openFullPageView = () => setIsFullPageView(true);
  const closeFullPageView = () => {
    setIsFullPageView(false);
    onBack();
  };

  const openPopup = (index = null) => {
    setIsEditing(index !== null);
    setEditIndex(index);
    if (index !== null) {
      setNoteText(notes[index]?.investigations_name || '');
      setDescription(notes[index]?.investigations_description || '');
      setTemplateName(notes[index]?.investigation_template_name || '');
    } else {
      setNoteText('');
      setDescription('');
      setTemplateName('');
    }
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsEditing(false);
    setNoteText('');
    setDescription('');
    setTemplateName('');
  };

  const saveAndClose = async () => {
    const noteData = {
        investigations_name: noteText,
        investigations_description: description,
    };

    const investigationId = notes[editIndex]?.investigations_id; // Get investigation ID

    if (isEditing && editIndex !== null) {
      // Editing an existing note
      const investigationId = notes[editIndex]?.investigations_id; // Get investigation ID

      if (investigationId) {
          try {
              // Send PATCH request to update the specific note
              await axios.patch(
                  `${API_BASE_URL}/api/v1/template-creator/investigations/${doctor_id}/${investigationId}/`,
                  { ...noteData },
                  { headers: { 'Authorization': `JWT ${authTokens?.access}` } }
              );

              // Update the local notes state
              const updatedNotes = [...notes];
              updatedNotes[editIndex] = { ...updatedNotes[editIndex], ...noteData };
              setNotes(updatedNotes);
          } catch (error) {
              console.error('Error updating note:', error);
          }
      }
  } else {
        // Ensure notes is an array before adding a new note
        try {
          

            const requestData = {
                doctor_id: doctor_id, 
                investigation_template_name: templateName, 
                investigation: [noteData], 
            };
            console.log("bbmnnnn",requestData)
            await axios.post(
                `${API_BASE_URL}/api/v1/template-creator/investigations/`,
                requestData,
                { headers: { Authorization: `JWT ${authTokens?.access}` } }
            );

            setNotes((prevNotes) => [
              ...prevNotes,
              { ...noteData}, // Add the new note with its ID
            ]);
        } catch (error) {
            console.error('Error adding note:', error);
        }
    }

    closePopup();
};




  const deleteInvestigation = async (investigationId) => {
    try {
      // Send DELETE request to API
      await axios.delete(
        `${API_BASE_URL}/api/v1/template-creator/investigations/${doctor_id}/${investigationId}/`,
        {
          headers: { Authorization: `JWT ${authTokens?.access}` },
        }
      );

      // Update local state by filtering out the deleted investigation
      const updatedNotes = notes.filter(note => note.investigations_id !== investigationId);
      setNotes(updatedNotes);

      console.log('Investigation deleted successfully.');
    } catch (error) {
      console.error('Error deleting investigation:', error);
    }
  };

  return (
    <div>
      {isFullPageView ? (
        <div className="full-page-view">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Investigation Notes</h4>
            <button onClick={closeFullPageView} className="acc-btn" style={{ padding: '5px 17px' }}>
              Back
            </button>
          </div>

          <label style={{ marginLeft: '5px', fontWeight: '500' }}>Search Notes</label>
          <div>
            <input
              type="text"
              placeholder="Search Notes..."
              className="search-input"
            />
            <button onClick={() => openPopup()} className="acc-btn">Add More</button>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th style={{ whiteSpace: 'nowrap' }}>Sl. No.</th>
                  <th>Notes</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(notes) && notes.length > 0 ? (
                  notes.map((note, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{note.investigations_name}</td>
                      <td>{note.investigations_description}</td>
                      <td>
                        <button onClick={() => openPopup(index)} className="edit-btn">Edit</button>
                        <button onClick={() => deleteInvestigation(note.investigations_id)} className="delete-btn">Delete</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No notes available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <div className="diagnosis-data">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Investigation Notes</h4>
            <button onClick={openFullPageView} className="acc-btn">
              View All
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(notes) && notes.length > 0 ? (
                notes.map((note, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{note.investigations_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No notes available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <div className="d-flex justify-content-between">
              <h4 style={{ whiteSpace: 'nowrap' }}>{isEditing ? 'Edit Note' : 'Add New Note'}</h4>
              <div>
                <button onClick={saveAndClose} className="acc-btn mx-1">Save and Close</button>
                <button onClick={closePopup} className="acc-btn">Back</button>
              </div>
            </div>

            <h6 className='me-2'>Investigation TemplateName :</h6>
            <textarea
              placeholder="Enter investigation template name here..."
              className="textarea-input"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              style={{height:"80px"}}
            />
            <h6 className='me-2'>Investigation Note :</h6>
            <textarea
              placeholder="Enter your note here..."
              className="textarea-input"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              style={{height:"80px"}}
            />
            <h6 className='me-2'>Investigation Description :</h6>
            <textarea
              placeholder="Enter description here..."
              className="textarea-input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{height:"80px"}}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InvestigationNotes;
