


import React, { useState, useEffect ,useContext} from 'react';
import axios from 'axios';
import './style.css';
import AuthContext from '../../context/AuthContext';
import config from '../../config';

function Diagnosis({ onBack }) {
  const { authTokens } = useContext(AuthContext);
  const [isFullPageView, setIsFullPageView] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState('');
  const [diagnosisName, setDiagnosisName] = useState('');
  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctorId = doctorData?.id;

  // Fetch diagnosis notes on component mount
  useEffect(() => {
    fetchDiagnosisNotes();
  }, []);

  const fetchDiagnosisNotes = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/${doctorId}/`,
        {
          headers: {
            "Content-Type": "application/json", // Optional for GET requests
            Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
        },
        }
      );
      setNotes(response.data); // Assuming API returns an array of notes
    } catch (error) {
      console.error('Error fetching diagnosis notes:', error);
    }
  };

console.log(notes)
  const openFullPageView = () => {
    setIsFullPageView(true);
  };

  const closeFullPageView = () => {
    setIsFullPageView(false);
    onBack(); // Call the onBack function to return to the Data component
  };

  const openPopup = (index = null) => {
    setIsEditing(index !== null);
    setEditIndex(index);
    setNoteText(index !== null ? notes[index].diagnosis_description : '');
    setDiagnosisName(index !== null ? notes[index].diagnosis_name : '');
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsEditing(false);
    setNoteText('');
    setDiagnosisName('');
  };

  const saveAndClose = async () => {
    if (isEditing) {
      // Update existing note
      try {
        const noteId = notes[editIndex].diagnosis_id;
        const updatedNote = {
          doctor_id: doctorId,
          diagnosis_name: diagnosisName,
          diagnosis_description: noteText,
        };
        await axios.patch(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/${doctorId}/${noteId}/`, updatedNote,
          {
            headers: {
              "Content-Type": "application/json", // Optional for GET requests
              Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
          },
          }
        );
        const updatedNotes = notes.map((note, index) =>
          index === editIndex ? { ...note,  diagnosis_name: diagnosisName } : note
        );
        setNotes(updatedNotes);
      } catch (error) {
        console.error('Error updating diagnosis note:', error);
      }
    } else {
      // Add new note
      try {
        const newNote = {
          doctor_id: doctorId,
          diagnosis_name: diagnosisName,
          diagnosis_description: "A condition in which the blood pressure in the arteries is persistently elevated.",
        };
        const response = await axios.post(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/`, newNote,
          {
            headers: {
              "Content-Type": "application/json", // Optional for GET requests
              Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
          },
          }
        );
        setNotes([...notes, response.data]);
      } catch (error) {
        console.error('Error adding diagnosis note:', error);
      }
    }
    closePopup();
  };

  const deleteNote = async (index) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/api/v1/template-creator/diagnosis/${doctorId}/${notes[index].diagnosis_id}/`,
        {
          headers: {
            "Content-Type": "application/json", // Optional for GET requests
            Authorization: `JWT ${authTokens?.access}`, // Attach the JWT token
        },
        }
      );
      const updatedNotes = notes.filter((_, i) => i !== index);
      setNotes(updatedNotes);
    } catch (error) {
      console.error('Error deleting diagnosis note:', error);
    }
  };

  return (
    <div>
      {isFullPageView ? (
        <div className="full-page-view">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Diagnosis Notes</h4>
            <button onClick={closeFullPageView} className="acc-btn" style={{ padding: '5px 17px' }}>
              Back
            </button>
          </div>

          <label style={{ marginLeft: '5px', fontWeight: '500' }}>Search Notes</label>
          <div>
            <input
              type="text"
              placeholder="Search Notes..."
              className="search-input"
            />
            <button onClick={() => openPopup()} className="acc-btn">Add More</button>
          </div>
          <table>
            <thead>
              <tr>
                <th style={{ whiteSpace: 'nowrap' }}>Sl. No.</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={note.id}>
                  <td>{index + 1}</td>
                  <td>{note.diagnosis_name}</td>
                  <td>
                    <button onClick={() => openPopup(index)} className="edit-btn">Edit</button>
                  </td>
                  <td>
                    <button onClick={() => deleteNote(index)} className="delete-btn">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="diagnosis-data">
          <div className="d-flex justify-content-between">
            <h4 style={{ color: '#0A9396' }}>Diagnosis Notes</h4>
            <button onClick={openFullPageView} className="acc-btn">
              View All
            </button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={note.id}>
                  <td>{index + 1}</td>
                  <td>{note.diagnosis_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <div className="d-flex justify-content-between">
              <h4 style={{ whiteSpace: 'nowrap' }}>{isEditing ? 'Edit Note' : 'Add New Note'}</h4>
              <div>
                <button onClick={saveAndClose} className="acc-btn mx-1">Save and Close</button>
                <button onClick={closePopup} className="acc-btn">Back</button>
              </div>
            </div>
            <textarea
              placeholder="Enter your note here..."
              className="textarea-input"
              value={diagnosisName}
              onChange={(e) => setDiagnosisName(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Diagnosis;
