

import React, { useContext, useEffect, useState ,useRef} from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import ConsultationContext from "../context/ConsultationContext";
import config from "../config";
import AuthContext from "../context/AuthContext";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import './style.css';
import { SlArrowDown } from "react-icons/sl";
import { motion } from "framer-motion";
import axios from "axios";


export default function BillingPage() {

  const doctorData = JSON.parse(sessionStorage.getItem('userData'));
  const doctor_id = doctorData?.id;
  
  const navigate = useNavigate();
  const location = useLocation();
  const { appointmentid } = location.state;
  const { ConsultationPatch, PrescriptionPrint } =
    useContext(ConsultationContext);
  const { authTokens } = useContext(AuthContext);
  const [followUpDate, setFollowUpDate] = useState();
  const [referTo, setReferTo] = useState("");
  const [allPrxData, setallPrxData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("ENG");
  const [height, setHeight] = useState();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showBilling, setShowBilling] = useState(false);
  const [weight, setWeight] = useState();
  const [specialitization, setSpecialitization] = useState("");
  const [registrationFeeService, setRegistrationFeeService] = useState(null);
    const [firstVisitFeeService, setFirstVisitFeeService] = useState(null);
    const [reVisitFeeService, setReVisitFeeService] = useState(null);
    const [Patientdata, setPatientdata] = useState(null);
    const [patientId, setpatientId] = useState(null);
    const [PatientType, setPatientType] = useState("");

//this is  will add
    const type = PatientType;
    useEffect(() => {
      const fetchFeeStructure = async () => {
          try {
              const response = await fetch(`${config.API_BASE_URL}/api/v1/fee-structure/self/`, {
                  method: "GET",
                  headers: { Authorization: `JWT ${authTokens?.access}` },
              });
  
              const data = await response.json();
  
              // Extract specific services
              const registrationService = data.find(
                  (item) => item.fee_type_name === "Registration Fee" && item.cost_type === "DF"
              );
  
              // Example type - replace with actual type variable from props, state, or other source
               // Replace with actual dynamic value
  
              const firstVisitService =
                   data.find(
                            (item) => item.fee_type_name === "First Visit Fee" && item.cost_type === "DF"
                        )
                      
  
              const reVisitService =
                  data.find(
                            (item) => item.fee_type_name === "Re-Visit Fee" && item.cost_type === "DF"
                        )
                      
  
              setRegistrationFeeService(registrationService || null);
              setFirstVisitFeeService(firstVisitService || null);
              setReVisitFeeService(reVisitService || null);
          } catch (error) {
              console.error("Error fetching fee structure:", error);
          }
      };
  
      fetchFeeStructure();
  }, [authTokens]);
  

    useEffect(() => {
      const fetchPatientDetails = async () => {
        if (!appointmentid) return;
    
        try {
          const response = await fetch(
            `${config.API_BASE_URL}/api/v1/appointment/user-appointment/${appointmentid}/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `JWT ${authTokens?.access}`,
              },
            }
          );
          
          if (response.ok) {
            const data = await response.json();
      
            console.log("lipi",data)
            setPatientdata(data)
            setpatientId(data.patient_id)
            setPatientType(data.type)
        
      
          } else {
            console.error("Failed to fetch patient details.");
          
          }
        } catch (error) {
          console.error("Error fetching patient details:", error);
        }
      };
    
      fetchPatientDetails();
    }, [authTokens]);
    console.log(Patientdata)

    const service = [
      {
          name: registrationFeeService?.fee_type_name || "Registration Fee",
          amount: registrationFeeService?.fee_amount || "N/A",
      },
      {
          name: firstVisitFeeService?.fee_type_name || "First Visit Fee",
          amount: firstVisitFeeService?.fee_amount || "N/A",
      },
      {
          name: reVisitFeeService?.fee_type_name || "Re-Visit Fee",
          amount: reVisitFeeService?.fee_amount || "N/A",
      },
  ];
  
  useEffect(() => {
    fetch(
      `${config.API_BASE_URL}/api/v1/consultation/rx/?appointment_id=${appointmentid}`,
      {
        method: "GET",
        headers: { Authorization: `JWT ${authTokens?.access}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setallPrxData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [authTokens, appointmentid]);
  const [clinicName, setClinicName] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [clinicPhone, setClinicPhone] = useState("");
  const [timing, setTiming] = useState("");
 

  
  

 



//this is  will add
  const [billdata, setbilldata] = useState();
 
    const fetchBillingData = async () => {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/v1/billing-app/billing/${doctor_id}/${appointmentid}/`,
          {
            method: "GET",
            headers: {
              Authorization: `JWT ${authTokens?.access}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          console.error(`API returned an error: ${response.status} ${response.statusText}`);
          return;
        }
  
        const data = await response.json();
        setbilldata(data); // Assuming `setbilldata` is a state setter for bill data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
      fetchBillingData();

  }, [authTokens]);
 console.log("bill",billdata)

//this is  will add
 const [vaccines, setVaccines] = useState([]);
  const [filteredVaccines, setFilteredVaccines] = useState([]);

  const fetchVaccines = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/v1/vaccine-app/assignments/patient/${appointmentid}/`, {
        headers: { "Authorization": `JWT ${authTokens?.access}` }
      });
      console.log(response);
      setVaccines(response.data);
    } catch (error) {
      console.error('Error fetching vaccines:', error);
      
    }
  };

  useEffect(() => {
    
    fetchVaccines();
    
  }, [authTokens]); // Runs when doctorId or authTokens change

  // console.log("Today's Date:", today);
vaccines.forEach(vaccine => {
  vaccine.dosages.forEach(dosage => {
    console.log("Dosage Date:", dosage.scheduled_date);
  });
});
console.log("Vaccines Data:", vaccines);


  useEffect(() => {
    console.log("jhgvhm")
    // Get today's date in 'YYYY-MM-DD' format
    const today = new Date().toISOString().split('T')[0];
  
    // Filter vaccines with dosages scheduled for today
   
    const filtered = vaccines.filter(vaccine =>
      vaccine.dosages.some(dosage => dosage.scheduled_date === today)
      
    );


  
    // Map filtered vaccines to include only today's dosages
    const filteredData = filtered.map(vaccine => ({
      vaccine_name: vaccine.vaccine_name,
      vaccine_price: vaccine.vaccine_price,
      patient_id: vaccine.patient_id,
      dosages: vaccine.dosages.filter(dosage => dosage.scheduled_date === today),
    }));
   console.log("datavacc",filteredData)
    setFilteredVaccines(filteredData);
  }, [vaccines]);
  
  


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/v1/fee-structure/self/`, {
          method: 'GET',
          headers: { 'Authorization': `JWT ${authTokens?.access}` },
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const filteredServices = data.filter(item => item.cost_type === 'SC');
        setServices(filteredServices);
      } catch (error) {
        console.error('Error fetching services data:', error);
      }
    };
    // const storedServices = localStorage.getItem('patientSelectedServices');
    // if (storedServices) {
    //   const allPatientServices = JSON.parse(storedServices);
    //   setSelectedServices(allPatientServices[appointmentid] || []);
    // }

    fetchServices();
  }, [authTokens]);


  // useEffect(() => {
  //   const storedServices = localStorage.getItem('patientSelectedServices');
  //   if (storedServices) {
  //     const allPatientServices = JSON.parse(storedServices);
  //     setSelectedServices(allPatientServices[appointmentid] || []);
  //   }
  // }, [appointmentid]);
  localStorage.setItem('patientSelectedServices', JSON.stringify({
    [appointmentid]: services.map(service => ({
      id: service.id,
      fee_type_name: service.fee_type_name,
      fee_amount: service.fee_amount
    }))
  }));

  useEffect(() => {
    // Fetching items from localStorage
    setClinicName(localStorage.getItem("clinicName") || "");
    setClinicAddress(localStorage.getItem("clinicAddress") || "");
    setClinicPhone(localStorage.getItem("phone") || "");
    setTiming(localStorage.getItem("timing") || "");
  }, []);
  
  
  
  


//  const patientid = `${Patientdata?.patient_details.id}` ;
useEffect(() => {
  console.log("Patient ID:", patientId);
  console.log("Auth Tokens:", authTokens);

  const fetchPatientVitals = async () => {
    if (!patientId || !authTokens?.access) {
      console.warn("Patient ID or Auth Tokens are not available.");
      return;
    }

    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/v1/vitals/vitals/${patientId}/`,
        {
          headers: { Authorization: `JWT ${authTokens?.access}` },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        console.log("Vitals data:", data);
        setHeight(data.height || "");
        setWeight(data.weight || "");
      } else {
        console.error("Vitals not found");
        setHeight("");
        setWeight("");
      }
    } catch (error) {
      console.error("Error fetching vitals:", error);
      setHeight("");
      setWeight("");
    }
  };

  fetchPatientVitals();
}, [patientId, authTokens]);



console.log("id",patientId)
  const submitservices = async () => {
    if (selectedServices.length === 0) {
      alert("Please select at least one service.");
      return;
    }
  
    // Get the selected services' details (name and cost)
    const selectedServiceDetails = services
      .filter((service) => selectedServices.includes(service.id))
      .map((service) => ({
        service_name: service.fee_type_name,
        cost_of_service: parseFloat(service.fee_amount) || 0,
      }));
  


    // Prepare the data to send to the backend
    const payload = {
      doctor_id: doctor_id,  // Replace with dynamic doctor_id
      patient_id: patientId, 
      appointment_id: appointmentid,  // This is the current appointment ID
      services: selectedServiceDetails,
    };
  
    try {
      // Send the data to the backend
      const response = await fetch(`${config.API_BASE_URL}/api/v1/billing-app/billing/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authTokens?.access}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        setShowBilling(true);
        
        alert("Services submitted successfully!");
        await  fetchBillingData();
      } else {
        const errorData = await response.json();
        console.error("Failed to submit services:", errorData);
        alert("Failed to submit services. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting services:", error);
      alert("An error occurred. Please try again later.");
    }
  };
  
 
  const handleServiceChange = (serviceId) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(serviceId)
        ? prevSelected.filter((id) => id !== serviceId)
        : [...prevSelected, serviceId]
    );
  };
  

  
  

  const handlePrint = async () => {
    try {
      //  console.log(appointmentid,
      //   referTo,
      //   followUpDate,
      //   height,
      //   weight,
      //   specialitization,
      //   clinicName,
      //   clinicAddress,
      //   clinicPhone,
      //   timing,);
      await PrescriptionPrint(
        appointmentid,
        referTo,
        followUpDate,
        height || "NA" ,
        weight || "NA",
        specialitization,
        clinicName,
        clinicAddress,
        clinicPhone,
        timing
      );
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  
  // localStorage.setItem('selectedServices', JSON.stringify(services));

  const calculateTotal = (services, selectedServices, service, billdata, filteredVaccines) => {
    let total = 0;
  
    // Add registration, first visit, or re-visit fees
    const standardServiceFees = service
      ? service.reduce((acc, curr) => acc + (parseFloat(curr.amount) || 0), 0)
      : 0;
  
    // Add additionally selected services
    const selectedServiceFees = services
      .filter((s) => selectedServices.includes(s.id))
      .reduce((acc, curr) => {
        // Ensure we're not double-counting services already in the standard fees
        if (!service.some(std => std.name === curr.fee_type_name)) {
          return acc + (parseFloat(curr.fee_amount) || 0);
        }
        return acc;
      }, 0);
  
    // Add bill data services (if not already counted)
    const billServiceFees = billdata && billdata.services
      ? billdata.services.reduce((acc, curr) => {
          // Avoid double-counting services already in standard or selected services
          if (!service.some(std => std.name === curr.services_name) && 
              !services.some(s => s.fee_type_name === curr.services_name)) {
            return acc + (parseFloat(curr.cost_of_service) || 0);
          }
          return acc;
        }, 0)
      : 0;
  
    // Add unique vaccine prices (only once per unique vaccine)
    const uniqueVaccineFees = filteredVaccines.reduce(
      (acc, vaccine) => {
        // Use a Set to ensure unique vaccines
        const uniqueVaccines = new Set();
        if (!uniqueVaccines.has(vaccine.vaccine_name)) {
          uniqueVaccines.add(vaccine.vaccine_name);
          return acc + (parseFloat(vaccine.vaccine_price) || 0);
        }
        return acc;
      }, 
      0
    );
  
    // Calculate the total
    total = standardServiceFees + selectedServiceFees + billServiceFees + uniqueVaccineFees;
  
    return total;
  };

  const generateBillingPDF = (
    appointmentid,
    Patientdata,
    selectedServices,
    services,
    calculateTotal,
    billdata,
    service,
    filteredVaccines
  ) => {
    const doc = new jsPDF();
  
    // Header
    doc.setFontSize(18);
    doc.text("Billing Details", 105, 40, null, null, "center");
    doc.setFontSize(12);
    doc.text(`${doctorData?.first_name}${doctorData?.last_name}`, 14, 20);
    doc.text("Reg. No: None", 14, 25);
  
    // Line Separators
    const titleY = 35;
    doc.setDrawColor(169, 169, 169);
    doc.setLineWidth(0.5);
    doc.line(14, titleY - 5, 200, titleY - 5);
    doc.line(14, titleY + 8, 200, titleY + 8);
    doc.line(14, titleY + 40, 200, titleY + 40);
  
    // Patient Information
    // const patientInfo = Patientdata.find((p) => p.id === appointmentid);
    doc.text(`Patient ID: ${Patientdata.id}`, 14, 60);
    doc.text(`Patient Name: ${Patientdata.patient_details?.first_name || "N/A"} ${Patientdata.patient_details?.last_name || " "}`, 14, 65);
    doc.text(
      `Age/Sex: ${Patientdata.patient_details?.age || " "} - ${Patientdata.patient_details?.gender || " "}`,
      14, 70
    );
    const currentDate = new Date().toLocaleString();
    doc.text(`Date & Time: ${currentDate}`, 120, 60);
    doc.text(`Mobile No.: ${Patientdata.patient_details?.phone_number || " "}`, 120, 65);
    doc.text(`Address: ${Patientdata.patient_details?.address || " "}`, 120, 70);
  
    // Billing Details Section
    doc.setFontSize(14);
    doc.text("Billing Details", 14, 90);
  
    // Prepare Table Data
    const tableColumn = ["Service", "Price"];
    const tableRows = [];
   

    if (registrationFeeService  && registrationFeeService.fee_amount !== "N/A") {
      tableRows.push([
        registrationFeeService.fee_type_name || "Registration Fee",
        registrationFeeService.fee_amount || "N/A",
      ]);
    }
    
    if (type === "IA" && firstVisitFeeService && firstVisitFeeService.fee_amount !== "N/A" ) {
      tableRows.push([
        firstVisitFeeService.fee_type_name || "First Visit Fee",
        firstVisitFeeService.fee_amount || "N/A",
      ]);
    }
    
    if (type === "FA" && reVisitFeeService && reVisitFeeService.fee_amount !== "N/A") {
      tableRows.push([
        reVisitFeeService.fee_type_name || "Re-Visit Fee",
        reVisitFeeService.fee_amount || " ",
      ]);
    }
    
  
   
  
    // Add services from `billdata.services`
    if (billdata && billdata.services) {
      billdata.services.forEach((s) => {
        tableRows.push([s.services_name, s.cost_of_service]);
        
      });
    }
  
    // Add vaccines from `filteredVaccines`
    if (filteredVaccines.length === 0) {
      // tableRows.push(["No scheduled doses for today.", ""]);
    } else {
      filteredVaccines.forEach((vaccine) => {
        vaccine.dosages.forEach((dosage) => {
          tableRows.push(
            [`${vaccine.vaccine_name} (Dose-${dosage.dosage_number})`, vaccine.vaccine_price]
          );
          
        });
      });
    }
  
    // Generate Table
    doc.autoTable(tableColumn, tableRows, { startY: 95 });
  
    // Total Price
    const finalY = doc.lastAutoTable.finalY || 95;
    const totalAmount = calculateTotal(
      services,
      selectedServices,
      service,
      billdata,
      filteredVaccines
    );
    doc.text(`Total:  ${totalAmount.toFixed(2)}`, 14, finalY + 10);
  
    // Return PDF Blob
    return doc.output("blob");
  };
  
  
  const uploadPdfToBackend = async (appointmentid, pdfBlob, authTokens) => {
    const formData = new FormData();
    formData.append("appointment_id", appointmentid);
    if (pdfBlob && pdfBlob.size > 0) {
      formData.append("pdf", pdfBlob, `Billing_Details_${appointmentid}.pdf`);
    } else {
      console.error("Invalid PDF Blob");
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/api/v1/billing-app/billing/upload-pdf/`,
        formData,
        {
          headers: {
            'Authorization': `JWT ${authTokens?.access}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("PDF successfully sent to backend:", response.data);
      
    } catch (error) {
      console.error("Error uploading PDF:", error.response?.data || error.message);
    }
  };
  
  // Updated handleSubmit
  const handleSubmit = async () => {
    try {
      console.log("submitted");
      await ConsultationPatch(
        appointmentid,
        referTo,
        followUpDate,
        height || "NA",
        weight || "NA",
        specialitization,
        clinicName,
        clinicAddress,
        clinicPhone,
        timing,
        selectedServices
      );
  
      // Generate PDF and upload to backend
      const pdfBlob = generateBillingPDF(
        appointmentid,
        Patientdata,
        selectedServices,
        services,
        calculateTotal,
        billdata,
        service,
        filteredVaccines
      );
      await uploadPdfToBackend(appointmentid, pdfBlob, authTokens);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };
  
  // Updated handlePrintbill
  const handlePrintbill = async () => {
    const pdfBlob = generateBillingPDF(
      appointmentid,
      Patientdata,
      selectedServices,
      services,
      calculateTotal,
      billdata,
      service,
      filteredVaccines
    );
    
  
    // Display PDF on the frontend
    const pdfDataUri = URL.createObjectURL(pdfBlob);
    const printWindow = window.open(pdfDataUri, "_blank");
    printWindow.document.write(`
      <html>
        <head><title>Billing Details PDF</title></head>
        <body><embed width="100%" height="100%" src="${pdfDataUri}" /></body>
      </html>
    `);
    printWindow.document.close();
  
    // Upload to backend
    await uploadPdfToBackend(appointmentid, pdfBlob, authTokens);
  };
  

  
console.log("reVisitFeeService?.fee_amount",reVisitFeeService)
  
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  return (
    <div className="page-grid">
      <div className="topbar">
        <div className="start-div">
          <div
            className="menu"
            onClick={() =>
              navigate("/doc/newconsult", {
                state: { appointmentid: appointmentid },
              })
            }
          >
            <IoMdArrowRoundBack
              className="icon"
              aria-label="Back"
              onClick={() =>
                navigate("/newconsult", {
                  state: { appointmentid: appointmentid },
                })
              }
            />
          </div>
          <div className="brandname">Prescription</div>
        </div>
      </div>
      <div className="page-containt">
        <div className="bcard">
          <div className="formarea">
            <h2>Patient Details</h2>
            <input
  type="date"
  onChange={(e) => setFollowUpDate(e.target.value)}
  min={new Date().toISOString().split("T")[0]} // Disables past dates
/>

            {/* <input
  type="date"
  onChange={(e) => setFollowUpDate(e.target.value)}
  min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split("T")[0]} // Disables today and past dates
/> */}

            <input
              type="text"
              placeholder="Refer To"
              value={referTo}
              onChange={(e) => setReferTo(e.target.value)}
            />
            <input
              type="text"
              placeholder="Specialization"
              value={specialitization}
              onChange={(e) => setSpecialitization(e.target.value)}
            />

            {/* <select>
              <option value="ENG">Billing</option>
              <option value="ENG">Add Billing to PRx</option>
              <option value="ENG">Generate 3C</option>
            </select> */}
            
            <div style={{ position: 'relative', display: 'inline-block',backgroundColor:"white",borderRadius:"10px",padding:"17px 10px" }} ref={dropdownRef}>
      <div onClick={toggleDropdown} style={{display:"flex",justifyContent:"space-between"}}>
        {selectedServices.length > 0 ? `Selected (${selectedServices.length})` : 'Select Services'}
        <motion.div
                 animate={{ rotate: isOpen ? 180 : 0 }}
                 transition={{ duration: 0.3 }}
                 style={{ display: "flex", alignItems: "center" }}
               >
                 <SlArrowDown style={{width:"10px"}} />
        </motion.div>
      </div>
      
      {isOpen && (
        <>
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          zIndex: 1,
          border: '1px solid #ccc',
          borderRadius: '10px',
          backgroundColor: '#fff',
          padding: '10px',
          width:"100%",
          boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
          maxHeight: '200px',
          whiteSpace:"nowrap",
          overflowY: 'auto'
        }}>
          
          
          {services.length > 0 ? (
            services.map(service => (
              <div key={service.id} style={{display:"flex",padding:"8px", borderBottom:"2px solid #ddd", maxHeight:"100px"}} >
                <div style={{ color:"black",fontWeight:"500"}}>
                {service.fee_type_name} : ₹{service.fee_amount || '0.00'}
                </div>
               
                {/* <input
                    type="checkbox"
                    checked={selectedServices.includes(service.id)}
                    onChange={() => handleServiceChange(service.id)}
                    style={{ marginTop: '7px' }}
                  /> */}
                  <label className="switch" >
                  
                      <input
                        type="checkbox"
                        checked={selectedServices.includes(service.id)}
                        onChange={() => handleServiceChange(service.id)}
                      />
                      
                      <div className="slider round" style={{width:"25px",height:"25px",top:"2px"}}></div>
                  </label>
                 
              </div>
            ))
          ) : (
            <p>No services available</p>
          )}
          <div className="acc-btn" style={{textAlign:"center"}} onClick={submitservices}>Submit</div>
        </div>
        
        </>
      )}
      
    </div>
   

            
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="ENG">English</option>
              <option value="ENG">Hindi</option>
              <option value="ENG">Odia</option>
            </select>
          </div>

          
          <div className="billarea">
            <div className="bill">
              <div style={{ width: "100%", position: "relative" }}>
                {/* <div className="left"> */}
                <h1
                  style={{
                    fontSize: "2vw",
                    textAlign: "center",
                    paddingBottom: "15px",
                    borderBottom: "1px solid #ddd",
                    position:'relative'
                  }}
                >
                  Prescription
                </h1>
                <div style={{ position: "absolute", right: 0}}>
                  <table style={{ borderCollapse: "collapse", width: "fit" }}>
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                          colspan="2"
                        >
                          VITALS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Height
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {height}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Weight
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {weight}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          BMI
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {(weight / ((height/100) * (height/100))).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h2 style={{ fontSize: "1.2vw" }}>Symptoms</h2>
                <ul>
                  {allPrxData.symptoms?.map(
                    (data, index) =>
                      (
                        <li key={index}>
                          {data.symptom_name} - {data.duration} ,{" "}
                          {data.severity}
                        </li>
                      ) || <li>No symptoms found.</li>
                  )}
                </ul>
                <h2 style={{ fontSize: "1.2vw" }}>Findings</h2>
                <p>{allPrxData.finding}</p>
                <h2 style={{ fontSize: "1.2vw" }}>Diagnosis</h2>
                <p>{allPrxData.diagnosis}</p>
                <img src="/rx.jpg" alt="" style={{ height: "30px" }} />
                <table
                  style={{
                    marginBottom: "15px",
                    borderCollapse: "collapse",
                    overflowWrap: "anywhere",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        #
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Medicines
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Dosage
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Timing
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Modality
                      </th>
                      <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPrxData.medicines?.map((data, index) => (
                      <tr key={index}>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.medicine_name}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.medicine_dosage}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.medicine_timing}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.medicine_modality}
                        </td>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.medicine_duration}
                        </td>
                      </tr>
                    )) || (
                      <tr>
                        <td colSpan="3">No medicines found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <h2 style={{ fontSize: "1.2vw" }}>Instructions</h2>
                <ul>
                  {allPrxData.instructions?.map(
                    (data, index) =>
                      <li key={index}>{data.instruction}</li> || (
                        <li>No instructions found.</li>
                      )
                  )}
                </ul>
                <h2 style={{ fontSize: "1.5vw" }}>Investigation</h2>
                <ul>
                  {allPrxData.investigations?.map(
                    (data, index) =>
                      <li key={index}>{data.investigation_name}</li> || (
                        <li>No instructions found.</li>
                      )
                  )}
                </ul>


                  {/* <card
                  content={{ id: 5, heading: "Selected Services" }}
                  patient={appointmentid}
                  selectedServices={selectedServices.map(id => services.find(service => service.id === id))}/> */}
                {showBilling &&(
                  <>
                
  <div className="billing-section">
    <h2>Billing Details</h2>
    <table>
      <thead>
        <tr>
          {/* <th>Sl. No.</th> */}
          <th>Service Name</th>
          <th>Price (₹)</th>
        </tr>
      </thead>
      <tbody>
     
      {registrationFeeService && registrationFeeService.fee_amount !== "N/A" && (
  <tr>
    <td>{registrationFeeService?.fee_type_name || "Registration Fee"}</td>
    <td>{registrationFeeService.fee_amount}</td>
  </tr>
)}

{type === "IA" && firstVisitFeeService && firstVisitFeeService.fee_amount !== "N/A" && (
  <tr>
    <td>{firstVisitFeeService?.fee_type_name || "First Visit Fee"}</td>
    <td>{firstVisitFeeService.fee_amount}</td>
  </tr>
)}

{type === "FA" && reVisitFeeService && reVisitFeeService.fee_amount !== "N/A" && (
  <tr>
    <td>{reVisitFeeService?.fee_type_name || "Re-Visit Fee"}</td>
    <td>{reVisitFeeService.fee_amount}</td>
  </tr>
)}



      {billdata && billdata.services ? (
        <>

          {billdata.services.map((service) => (
                        <tr key={service.id}>
                            <td>{service.services_name}</td>
                            <td>{service.cost_of_service}</td>
                        </tr>
                    ))}
                    </>
                  ) : (
                    <></>
                  )}

{filteredVaccines.length === 0 ? (
          <>
          
          </>
        ) : (
          filteredVaccines.map((vaccine, vaccineIndex) =>
            vaccine.dosages.map((dosage, dosageIndex) => (
              <tr key={`${vaccineIndex}-${dosageIndex}`}>
                {/* <td>{vaccine.vaccine_name}</td> */}
                <td>{vaccine.vaccine_name}(Dose-{dosage.dosage_number})</td>
                {/* <td>{dosage.scheduled_date}</td> */}
                <td>{vaccine.vaccine_price}</td>
                {/* <td>{vaccine.patient_id}</td> */}
              </tr>
            ))
          )
        )}
                  
          
      </tbody>
    </table>
  </div>
  </>
)}




              </div>
              {/* <div className="right">
              </div> */}
            </div>
            <div className="billbuttonarea">
              <button
                className="hoverbutton"
                style={{ padding: "0px 3vw" }}
                onClick={handlePrint}
              >
                Print
              </button>
              <button
                className="hoverbutton"
                style={{ padding: "0px 3vw" }}
                onClick={handlePrintbill}
              >
                bill Print
              </button>
              <button
                onClick={() => handleSubmit()}
                className="hoverbutton"
                type="submit"
                style={{
                  padding: "0px 3vw",
                  borderColor: "#74c0c3",
                  backgroundColor: "#74c0c3",
                  color: "white",
                }}
              >
                Finish
              </button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}




