import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { FiSearch } from "react-icons/fi";
import './style.css';

const animatedComponents = makeAnimated();

export default function Searchbar({ options, placeholder, onValuesChange }) {
  const [value, setValue] = useState(null); // Change initial value to null
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleValuesChange = (selectedValues) => {
    console.log('Selected values:', selectedValues);
    if (Array.isArray(selectedValues) && selectedValues.length > 0) {
      console.log('Selected value:', selectedValues[0]);
      setValue(selectedValues[0]);
      if (onValuesChange) {
        onValuesChange([selectedValues[0]]);
      }
    } else {
      setValue(null);
      if (onValuesChange) {
        onValuesChange([]);
      }
    }
  };

  return (
    <div>
      <CreatableSelect
        closeMenuOnSelect={true}
        components={animatedComponents}
        value={value ? [value] : []} // Ensure value is always an array
        options={filteredOptions}
        placeholder={
          <div>
            <FiSearch id="search-icon" style={{ color: '#383c44', fontSize: "1.125rem" }} /> {placeholder}
          </div>
        }
        noOptionsMessage={() => 'Not Found....'}
        onChange={handleValuesChange}
        isSearchable
        isMulti
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
            border: '2px solid #0a9396',
            borderRadius: '0.625rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            margin:'4px'
          }),
          multiValue: (styles) => ({
            position: 'relative',
            display: 'flex',
            margin: '0px 5px 2px 0px',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'wrap',
            borderRadius: '10px',
          }),
          multiValueLabel: (styles) => ({
            color: 'black',
            fontSize: "1.5",
          }),
          multiValueRemove: (styles) => ({
            display: 'none', // Hide the remove button from multi-select values
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "#383C44",
            fontSize: "1rem"
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: "#000",
          }),
          clearIndicator: (baseStyles) => ({
            ...baseStyles,
            display: 'none', // Hide the clear button
          }),
        }}
      />
    </div>
  );
}
